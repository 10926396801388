<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 内容 -->
        <el-form
            ref="form"
            slot="body"
            label-width="10em"
            size="small"
        >
            <el-card
                size="small"
                shadow="never"
                header="商品信息"
            >
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    {{ goods.goodsName }}
                </el-form-item>
                <el-form-item
                    prop="goodsShortName"
                    label="商品简称"
                >
                    {{ goods.goodsShortName }}
                </el-form-item>
                <el-form-item
                    prop="goodsSubtitle"
                    label="商品副标题"
                >
                    {{ goods.goodsSubtitle }}
                </el-form-item>
                <el-form-item
                    prop="price"
                    label="商品价格"
                >
                    {{ goods.price }}
                </el-form-item>
                <el-form-item
                    prop="marketPrice"
                    label="商品划线价"
                >
                    {{ goods.marketPrice }}
                </el-form-item>
            </el-card>
            <el-card
                size="small"
                shadow="never"
                class="ma-t"
            >
                <el-table
                    stripe
                    border
                    size="small"
                    :data="shopGoodsAgentList"
                    style="width: 100%"
                    default-expand-all
                >
                    <!--                        <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="scope.row.skuList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="skuSn"
                                label="sku编码"
                                min-width="100"
                            />
                            <el-table-column
                                prop="specDesc"
                                label="商品规格"
                                min-width="100"
                            />
                            <el-table-column
                                prop="platPrice"
                                label="平台价格"
                                width="80"
                            />
                            <el-table-column
                                prop="showStock"
                                label="库存"
                                width="80"
                            />
                            <el-table-column
                                prop="price"
                                label="价格"
                                width="150"
                                v-if="(scope.row.paymentMethod & 1) !== 0"
                            >
                                <template slot-scope="scope1">
                                    <el-input-number
                                        controls-position="right"
                                        :min="0.01"
                                        :precision="2"
                                        v-model.number="scope1.row.price"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="pointNum"
                                label="积分"
                                width="150"
                                v-if="(scope.row.paymentMethod & 2) !== 0"
                            >
                                <template slot-scope="scope1">
                                    <el-input-number
                                        controls-position="right"
                                        :min="1"
                                        :precision="0"
                                        v-model.number="scope1.row.pointNum"
                                    />
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>-->
                    <el-table-column
                        prop="isAvailable"
                        label="是否可用"
                        width="70"
                    >
                        <template slot-scope="scope">
                            <el-switch
                                v-model="scope.row.isAvailable"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="shopName"
                        label="店铺名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="agentGoodsName"
                        label="商品名称"
                        width="200"
                    >
                        <template slot-scope="scope">
                            <el-input
                                v-model="scope.row.agentGoodsName"
                                auto-complete="off"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="shopGoodsCatId"
                        label="店铺类目"
                        width="250"
                    >
                        <template slot-scope="scope">
                            <TreePicker
                                :remote="'/sp/shopGoodsCat/selectAll?shopId=' + scope.row.shopId"
                                v-model="scope.row.shopGoodsCatId"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="price"
                        label="价格"
                        width="150"
                    >
                        <template slot-scope="scope">
                            <el-input-number
                                size="mini"
                                controls-position="right"
                                :min="0.01"
                                :precision="2"
                                v-model.number="scope.row.price"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="taxRate"
                        label="税率(%)"
                        width="150"
                    >
                        <template slot-scope="scope">
                            <el-select
                                size="mini"
                                v-model="scope.row.taxRate"
                                placeholder="请选择"
                            >
                                <el-option
                                    key="0"
                                    label="0%"
                                    :value="0"
                                />
                                <el-option
                                    key="5"
                                    label="6.3%"
                                    :value="6.3"
                                />
                                <el-option
                                    key="1"
                                    label="9.1%"
                                    :value="9.1"
                                />
                                <el-option
                                    key="4"
                                    label="17.9%"
                                    :value="17.9"
                                />
                                <el-option
                                    key="2"
                                    label="23.1%"
                                    :value="23.1"
                                />
                                <el-option
                                    key="3"
                                    label="29%"
                                    :value="29"
                                />
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="shopIncomeRate"
                        label="商户级分佣比例(%)"
                        width="150"
                    >
                        <template slot-scope="scope">
                            <el-input-number
                                size="mini"
                                controls-position="right"
                                :min="0"
                                :max="50"
                                :precision="2"
                                v-model.number="scope.row.shopIncomeRate"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="pointRate"
                        label="积分获取比例"
                        width="220"
                    >
                        <template slot-scope="scope">
                            <el-input-number
                                size="mini"
                                controls-position="right"
                                :min="0"
                                :precision="2"
                                v-model.number="scope.row.pointRate"
                            />
                            1元=？积分
                        </template>
                    </el-table-column>
                    <!--                    <el-table-column
                        prop="orderNum"
                        label="排序"
                        width="200"
                    >
                        <template slot-scope="scope">
                            <el-input-number
                                controls-position="right"
                                :min="1"
                                v-model.number="scope.row.orderNum"
                            />
                        </template>
                    </el-table-column>-->
                    <el-table-column
                        prop="pointGoodsCatId"
                        label="积分类目"
                        width="220"
                    >
                        <template slot-scope="scope">
                            <TreePicker
                                size="mini"
                                :remote="'/sp/pointGoodsCat/selectAll?shopId=' + scope.row.shopId"
                                v-model="scope.row.pointGoodsCatId"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="pointNum"
                        label="积分价格"
                        width="150"
                    >
                        <template slot-scope="scope">
                            <el-input-number
                                size="mini"
                                controls-position="right"
                                :min="1"
                                :precision="0"
                                v-model.number="scope.row.pointNum"
                            />
                        </template>
                    </el-table-column>
                </el-table>

                <div class="ma-t">
                    <span
                        class="ma-t"
                    >
                        <span class="batch">批量设置: </span>
                        <template v-for="(item,index) in formField">
                            <el-button
                                :key="index"
                                v-if="!item.hide"
                                @click="onBatch(item)"
                            >
                                {{ item.name }}
                            </el-button>
                        </template>
                    </span>
                </div>
            </el-card>
        </el-form>

        <div class="ta-c pa-a">
            <el-button
                size="small"
                type="primary"
                @click="onSave"
            >
                保 存
            </el-button>
            <el-button
                size="small"
                @click="$router.back()"
            >
                返 回
            </el-button>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'AddGoods',
    mixins: [pagesMixin],
    data() {
        return {
            shopGoodsAgentList: [],
            goods: {},
        };
    },
    computed: {
        goodsId() {
            return this.$route.params.goodsId;
        },
        formField() {
            const list = [];
            list.push({ key: 'price', name: '价格', required: true });
            list.push({ key: 'pointNum', name: '积分价格', required: true });
            list.push({ key: 'agentGoodsName', name: '商品名称', required: true });
            list.push({ key: 'taxRate', name: '税率', required: true });
            list.push({ key: 'shopIncomeRate', name: '商户级分佣比例', required: true });
            list.push({ key: 'pointRate', name: '积分比例', required: true });
            // list.push({ key: 'orderNum', name: '排序', required: true });
            return list;
        },
    },
    methods: {
        onSave() {
            this.shopGoodsAgentList.forEach(item => {
                item.shopGoodsCatIds = item.shopGoodsCatId
                    ? item.shopGoodsCatId
                          .reduce((prev, curr) => {
                              prev.push(curr);
                              return prev;
                          }, [])
                          .join(',')
                    : '';
                item.pointGoodsCatIds = item.pointGoodsCatId
                    ? item.pointGoodsCatId
                          .reduce((prev, curr) => {
                              prev.push(curr);
                              return prev;
                          }, [])
                          .join(',')
                    : '';
            });
            this.$api.Gd.GoodsAgent.saveShopGoodsBatchAgent({
                goodsId: this.goodsId,
                agentJson: JSON.stringify(this.shopGoodsAgentList),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
            });
        },
        init() {
            this.$api.Gd.GoodsAgent.getShopGoodsAgentList({
                goodsId: this.goodsId,
            }).then(json => {
                this.shopGoodsAgentList = json.data.data;
                this.shopGoodsAgentList.forEach(item => {
                    if (!item.pointNum) {
                        item.pointNum = undefined;
                    }
                    if (!item.price) {
                        item.price = undefined;
                    }
                });
            });
            this.$api.Gd.Goods.getDetail({ id: this.goodsId }).then(json => {
                this.goods = json.data.data;
            });
        },
        onBatch(field) {
            this.$prompt(`请输入${field.name}`, '温馨提示', {
                inputValidator(val) {
                    if (!val && field.required) {
                        return `${field.name}不能为空`;
                    }
                    if (
                        /price|pointNum|agentGoodsName|taxRate|shopIncomeRate|pointRate|orderNum/.test(field.key) &&
                        Number.isNaN(val)
                    ) {
                        return `请填写`;
                    }
                    return true;
                },
            }).then(({ value }) => {
                if (/price|pointNum|agentGoodsName|taxRate|shopIncomeRate|pointRate|orderNum/.test(field.key)) {
                    value = +value;
                }
                this.shopGoodsAgentList.forEach(item => {
                    if (field.key === 'taxRate') {
                        this.$set(item, field.key, +value);
                    } else {
                        this.$set(item, field.key, value);
                    }
                });
            });
        },
    },
    created() {
        this.init();
    },
};
</script>

<style lang="scss">
</style>
