<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.goodsStatusType"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="出售中"
                    name="1"
                />
                <el-tab-pane
                    label="已售罄"
                    name="2"
                />
                <el-tab-pane
                    label="仓库中"
                    name="3"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="storeId"
                    :label="$t('pageKey620', '门店')"
                >
                    <RemoteSelect
                        filterable
                        clearable
                        remote="/sp/shopStore/select"
                        :placeholder="$t('pageKey101', '请选择')"
                        :props="{id:'id',name:'storeName'}"
                        v-model="queryFormModel.storeId"
                        disabled-cache
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    label="商品货号"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        placeholder="请输入商品货号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatId"
                    label="商品类目"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="queryFormModel.goodsCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="supplierId"
                    label="供应商"
                >
                    <el-select
                        v-model="queryFormModel.supplierId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in supplierList"
                            :key="item.id"
                            :label="item.supplierName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        size="small"
                        @click="batchSetPackingAmount"
                    >
                        批量设置
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="id"
                    label="ID"
                    width="50"
                />
                <el-table-column
                    prop="storeName"
                    label="门店名称"
                    min-width="100"
                />
                <el-table-column
                    label="商品图片"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.specImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsSn"
                    label="商品货号"
                    min-width="50"
                />
                <el-table-column
                    prop="goodsCatName"
                    label="商品类目"
                    min-width="50"
                />
                <el-table-column
                    prop="supplierName"
                    label="供应商名称"
                    min-width="50"
                />
                <el-table-column
                    prop="price"
                    label="销售价"
                    min-width="50"
                />
                <el-table-column
                    prop="marketPrice"
                    label="市场价"
                    min-width="50"
                />
                <el-table-column
                    prop="stock"
                    label="库存"
                    min-width="50"
                />
                <el-table-column
                    prop="goodsStatusName"
                    label="商品状态"
                    min-width="50"
                />
                <el-table-column
                    prop="packingAmount"
                    label="包装费"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="scope.row.packingAmount"
                            @change="onPackingAmountInput(scope.row)"
                        />
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 批量设置 -->
        <el-dialog
            title="批量设置"
            center
            width="600px"
            :visible.sync="packingAmountDialog.isVisible"
            @closed="onPackingAmountDialogCancel"
        >
            <el-form
                ref="packingAmountForm"
                size="small"
                :model="packingAmountDialog.formModel"
                :rules="packingAmountDialog.formRules"
            >
                <el-form-item
                    label-width="6em"
                >
                    <span class="tc-red">批量设置查询出来的商品列表</span>
                </el-form-item>
                <!--                <el-form-item
                    key="batchSetType"
                    prop="batchSetType"
                    label="设置方式"
                    label-width="6em"
                >
                    <el-select v-model="packingAmountDialog.formModel.batchSetType">
                        <el-option
                            key="1"
                            label="固定数量"
                            value="1"
                        />
                        <el-option
                            key="2"
                            label="按比例"
                            value="2"
                        />
                    </el-select>
                </el-form-item>-->
                <el-form-item
                    key="packingAmount"
                    prop="packingAmount"
                    label="包装费"
                    label-width="6em"
                    v-if="packingAmountDialog.formModel.batchSetType == 1"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="packingAmountDialog.formModel.packingAmount"
                    />
                </el-form-item>
                <el-form-item
                    key="packingAmountPer"
                    prop="packingAmountPer"
                    label="增加比例"
                    label-width="6em"
                    v-if="packingAmountDialog.formModel.batchSetType == 2"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="packingAmountDialog.formModel.packingAmountPer"
                    />
                    %
                    <div>
                        以原包装费，按比例增加
                    </div>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onPackingAmountDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onPackingAmountDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'GoodsPackingAmount',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsName: '', // 商品名称
                goodsSn: '', // 商品货号
                goodsCatId: '', // 商品类目
                supplierId: '', // 供应商
                goodsStatusType: '1', // 商品状态类型
                storeId: '',
                showAllGoods: 1,
            },
            // 表格数据
            tableData: [],
            // 批量设置包装费
            packingAmountDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    batchSetType: '1',
                    packingAmount: '',
                    packingAmountPer: '',
                },
                // 表单校验规则
                formRules: {
                    batchSetType: {
                        required: true,
                        message: '请选择设置方式',
                    },
                    packingAmount: {
                        required: true,
                        message: '请输入包装费',
                        trigger: 'blur',
                    },
                    packingAmountPer: {
                        required: true,
                        message: '请输入增加比例',
                        trigger: 'blur',
                    },
                },
                // 订单数据
                orderData: {},
            },
            supplierList: [],
            selectApi: this.$api.Gd.GoodsCat,
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.Goods.findSkuMapList({
                ...queryData,
            }).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        initSupplierList() {
            this.$api.Gd.Supplier.select({}).then(json => {
                this.supplierList = json.data.data;
            });
        },
        onPackingAmountInput(row) {
            this.$api.Gd.Goods.changePackingAmount({ id: row.id, packingAmount: row.packingAmount });
        },
        // 批量设置包装费
        batchSetPackingAmount() {
            this.packingAmountDialog.isVisible = true;
        },
        onPackingAmountDialogConfirm() {
            const { packingAmountForm } = this.$refs;
            packingAmountForm.validate().then(() => {
                this.$api.Gd.Goods.batchSetPackingAmount({
                    ...this.packingAmountDialog.formModel,
                    ...this.queryFormModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.packingAmountDialog.isVisible = false;
                });
            });
        },
        onPackingAmountDialogCancel() {
            const { packingAmountForm } = this.$refs;
            packingAmountForm.resetFields();
            this.packingAmountDialog.isVisible = false;
        },
    },
    created() {
        this.queryFormModel.isPrivateCustom = 0;

        this.initSupplierList();
    },
};
</script>

<style lang="scss">
</style>
