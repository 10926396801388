<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.goodsStatusType"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    :label="$t('pageKey199', '出售中')"
                    name="1"
                />
                <el-tab-pane
                    :label="$t('pageKey200', '已售罄')"
                    name="2"
                />
                <el-tab-pane
                    :label="$t('pageKey201', '仓库中')"
                    name="3"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品货号')"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        :placeholder="$t('pageKey203', '请输入商品货号')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        :placeholder="$t('pageKey205', '请输入商品名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatId"
                    :label="$t('pageKey206', '商品类目')"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="queryFormModel.goodsCatId"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopGoodsCatId"
                    :label="$t('pageKey207', '店铺类目')"
                    v-if="userData.appId === 2"
                >
                    <CascaderPicker
                        :api-class="selectApi1"
                        v-model="queryFormModel.shopGoodsCatId"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    prop="supplierId"
                    :label="$t('pageKey103', '供应商')"
                    v-if="(userData.appId === 1 && !userData.platform.isSupplierQuotations) || userData.appId === 2"
                >
                    <el-select
                        v-model="queryFormModel.supplierId"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            v-for="item in supplierList"
                            :key="item.id"
                            :label="item.supplierName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="isSetSupplierQuotations"
                    :label="$t('pageKey208', '是否设置售卖优先级')"
                    v-if="userData.appId === 1 && (userData.platform && userData.platform.isSupplierQuotations)"
                >
                    <el-select
                        v-model="queryFormModel.isSetSupplierQuotations"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            key="1"
                            :label="$t('pageKey209', '是')"
                            value="1"
                        />
                        <el-option
                            key="2"
                            :label="$t('pageKey210', '否')"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        v-if="type === 1"
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/gd/goods/addGoods')"
                    >
                        {{ $t('pageKey15', '新增') }}
                    </el-button>
                    <el-button
                        v-if="type === 2"
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/cgd/goods/addCustomGoods')"
                    >
                        {{ $t('pageKey15', '新增') }}
                    </el-button>
                    <el-button
                        v-if="type === 3"
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/gd/goods/addBondedGoods')"
                    >
                        {{ $t('pageKey15', '新增') }}
                    </el-button>
                    <el-button
                        v-if="type === 4"
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/gd/goods/addDirectMailGoods')"
                    >
                        {{ $t('pageKey15', '新增') }}
                    </el-button>
                    <el-button
                        v-if="type === 5"
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/gd/goods/addCommunityGoods')"
                    >
                        {{ $t('pageKey15', '新增') }}
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        {{ $t('pageKey211', '导出') }}
                    </el-button>
                    <template v-if="userData.appId !== 3">
                        <el-button
                            icon="el-icon-refresh-right"
                            type="success"
                            size="small"
                            @click="onSyncGoods"
                            v-if="type === 5 && userData.shop.isSyncPospal"
                        >
                            {{ $t('pageKey212', '同步银豹商品') }}
                        </el-button>
                        <el-button
                            type="success"
                            size="small"
                            icon="el-icon-refresh-right"
                            @click="onSyncInventoryGoods"
                            v-if="(userData.shop && userData.shop.isPushInventory) || (userData.platform && userData.platform.isPushInventory)"
                        >
                            {{ $t('pageKey213', '同步进销存商品编码') }}
                        </el-button>
                        <el-button
                            type="success"
                            size="small"
                            icon="el-icon-refresh-right"
                            @click="onSyncInventoryStock"
                            v-if="(userData.shop && userData.shop.isPushInventory) || (userData.platform && userData.platform.isPushInventory)"
                        >
                            {{ $t('pageKey214', '同步进销存库存') }}
                        </el-button>
                    </template>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                :empty-text="$t('pageKey977', '暂无数据')"
            >
                <el-table-column
                    prop="id"
                    label="ID"
                    width="50"
                />
                <el-table-column
                    :label="$t('pageKey215', '商品图片')"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.mainImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品货号')"
                    min-width="70"
                />
                <el-table-column
                    prop="shopGoodsCatName"
                    :label="$t('pageKey207', '店铺类目')"
                    min-width="70"
                />
                <el-table-column
                    prop="goodsCatName"
                    :label="$t('pageKey206', '商品后台类目')"
                    min-width="70"
                />
                <el-table-column
                    prop="supplierName"
                    :label="$t('pageKey103', '供应商名称')"
                    min-width="80"
                    v-if="(userData.appId === 1 && !userData.platform.isSupplierQuotations) || userData.appId === 2"
                />
                <el-table-column
                    prop="price"
                    :label="$t('pageKey216', '销售价')"
                    min-width="60"
                    v-if="userData.appId !== 1 || (userData.platform && !userData.platform.isSupplierQuotations)"
                />
                <el-table-column
                    prop="marketPrice"
                    :label="$t('pageKey217', '市场价')"
                    min-width="60"
                />
                <el-table-column
                    prop="stock"
                    :label="$t('pageKey218', '库存')"
                    min-width="50"
                    v-if="userData.appId !== 1 || (userData.platform && !userData.platform.isSupplierQuotations)"
                >
                    <template slot-scope="scope">
                        {{ scope.row.stock }} ({{ scope.row.realStock }})
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsStatusName"
                    :label="$t('pageKey219', '商品状态')"
                    min-width="70"
                />
                <el-table-column
                    prop="quotationsStock"
                    :label="$t('pageKey220', '供应商总库存')"
                    width="100"
                    v-if="userData.appId === 1 && (userData.platform && userData.platform.isSupplierQuotations)"
                >
                    <template slot-scope="scope">
                        {{ scope.row.quotationsStock }} ({{ scope.row.realQuotationsStock }})
                    </template>
                </el-table-column>
                <el-table-column
                    prop="supplierQuotationsStock"
                    :label="$t('pageKey221', '供应商库存')"
                    min-width="100"
                    v-if="userData.appId === 1 && (userData.platform && userData.platform.isSupplierQuotations)"
                />
                <el-table-column
                    prop="orderNum"
                    :label="$t('pageKey222', '排序')"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-input-number
                            size="mini"
                            controls-position="right"
                            :min="0"
                            :max="999999"
                            v-model.number="scope.row.orderNum"
                            @change="onOrderNumInput(scope.row)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    :label="$t('pageKey13', '操作')"
                    width="180"
                >
                    <template slot-scope="scope">
                        <el-button
                            v-if="type === 1"
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/goods/addGoods/${scope.row.id}`)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            v-if="type === 2"
                            type="text"
                            size="small"
                            @click="$router.push(`/cgd/goods/addCustomGoods/${scope.row.id}`)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            v-if="type === 3"
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/goods/addBondedGoods/${scope.row.id}`)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            v-if="type === 4"
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/goods/addDirectMailGoods/${scope.row.id}`)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            v-if="type === 5"
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/goods/addCommunityGoods/${scope.row.id}`)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            v-if="type === 1 && userData.shop && userData.shop.shopStoreType !== 99"
                            type="text"
                            size="small"
                            @click="editGoodsExtendInfo(scope.row.id)"
                        >
                            {{ $t('pageKey223', '扩展信息') }}
                        </el-button>
                        <!--<el-button
type="text"
size="small"
@click="$router.push('/gd/goods/goods/skuMemberPrice/' + scope.row.id)"
>会员价
</el-button>
<el-button
type="text"
size="small"
@click="$router.push('/gd/goods/goods/skuDistributionPrice/' + scope.row.id)"
>分销价
</el-button>-->
                        <el-button
                            type="text"
                            size="small"
                            v-if="scope.row.goodsStatus == 1 || scope.row.goodsStatus == 4"
                            @click="onSaleGoods(scope.row)"
                        >
                            {{ $t('pageKey224', '上架') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            v-if="scope.row.goodsStatus == 3 || (scope.row.goodsStatus == 4 && scope.row.offShelvesReason == 2)"
                            @click="offSaleGoods(scope.row)"
                        >
                            {{ $t('pageKey225', '下架') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onGoodsForbidSell(scope.row)"
                            v-if="userData.shop && userData.shop.shopStoreType !== 99"
                        >
                            {{ $t('pageKey226', '禁售分销商') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onSupplierQuotations(scope.row)"
                            v-if="userData.appId === 1 && (userData.platform && userData.platform.isSupplierQuotations)"
                        >
                            {{ $t('pageKey227', '售卖优先级') }}
                        </el-button>
                        <!--                        <el-button
                            type="text"
                            size="small"
                            @click="onShopGoodsAgent(scope.row)"
                            v-if="userData.appId === 1"
                        >
                            商品代理
                        </el-button>-->
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/goods/goodsBatchAgent/${scope.row.id}`)"
                            v-if="userData.appId === 1 && scope.row.skuList.length === 1"
                        >
                            {{ $t('pageKey228', '商品代理') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/goods/goodsStoreBatchAgent/${scope.row.id}`)"
                            v-if="userData.appId === 2 && scope.row.skuList.length === 1 && (scope.row.goodsSendMode === 6 || scope.row.goodsType === 4)"
                        >
                            {{ $t('pageKey229', '门店代理') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/goods/goodsStoreBatchAgentStock/${scope.row.id}`)"
                            v-if="userData.appId === 2 && scope.row.skuList.length === 1 && (scope.row.goodsSendMode === 6 || scope.row.goodsType === 4)"
                        >
                            {{ $t('pageKey230', '门店库存') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onCopyGoods(scope.row)"
                            v-if="scope.row.goodsSendMode === 6 && userData.appId !== 3"
                        >
                            {{ $t('pageKey231', '复制到快递产品') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!--扩展信息-->
        <el-dialog
            :title="$t('pageKey223', '扩展信息')"
            center
            width="900px"
            :visible.sync="editDialog.isVisible"
            @closed="onEditDialogCancel"
        >
            <el-form
                ref="editForm"
                size="small"
                :model="editDialog.formModel"
                :rules="editDialog.formRules"
            >
                <el-tabs
                    type="card"
                    v-model="editTab"
                >
                    <el-tab-pane
                        :label="$t('pageKey232', '分享文案')"
                        name="1"
                    >
                        <el-form-item
                            :label="$t('pageKey233', '文案列表')"
                            label-width="6em"
                        >
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="editDialog.goodsShareCopyList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    :label="$t('pageKey234', '文案名称')"
                                    width="150"
                                >
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.copyName"
                                            auto-complete="off"
                                        />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    :label="$t('pageKey235', '文案内容')"
                                    min-width="150"
                                >
                                    <template slot-scope="scope">
                                        <el-input
                                            type="textarea"
                                            v-model="scope.row.copyContent"
                                            auto-complete="off"
                                        />
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-tab-pane>
                    <el-tab-pane
                        :label="$t('pageKey236', '商品素材')"
                        name="2"
                    >
                        <el-tabs
                            tab-position="left"
                            v-model="goodsStyleTab"
                        >
                            <el-tab-pane
                                v-for="item in editDialog.goodsMultiStyleList"
                                :key="item.goodsStyleId"
                                :label="item.styleName"
                                :name="item.styleName"
                            >
                                <el-form-item
                                    label-width="6em"
                                    prop="goodsName"
                                    :label="$t('pageKey204', '商品名称')"
                                >
                                    <el-input
                                        :placeholder="$t('pageKey205', '请输入商品名称')"
                                        v-model="item.goodsName"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label-width="6em"
                                    prop="goodsSubtitle"
                                    :label="$t('pageKey237', '商品副标题')"
                                >
                                    <el-input
                                        :placeholder="$t('pageKey238', '请输入')"
                                        v-model="item.goodsSubtitle"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label-width="6em"
                                    prop="goodsImage"
                                    :label="$t('pageKey215', '商品列表图')"
                                >
                                    <upload
                                        draggable
                                        validate-md5
                                        action="/rs/attachment/uploadFileLibrary"
                                        :limit="12"
                                        :tips="$t('pageKey239', '建议尺寸：800*800像素,最多上传12张图片')"
                                        v-model="item.goodsImage"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label-width="6em"
                                    prop="goodsDetailImage"
                                    :label="$t('pageKey240', '商品详情图')"
                                >
                                    <upload
                                        draggable
                                        validate-md5
                                        action="/rs/attachment/uploadFileLibrary"
                                        :limit="12"
                                        :tips="$t('pageKey239', '建议尺寸：800*800像素,最多上传12张图片')"
                                        v-model="item.goodsDetailImage"
                                    />
                                </el-form-item>
                            </el-tab-pane>
                        </el-tabs>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onEditDialogConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onEditDialogCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <!--商品上架-->
        <el-dialog
            :title="$t('pageKey241', '商品上架')"
            center
            width="1200px"
            :visible.sync="onSaleDialog.isVisible"
            @closed="onSaleDialogCancel"
        >
            <el-form
                ref="onSaleForm"
                size="small"
                :model="onSaleDialog.formModel"
                :rules="onSaleDialog.formRules"
            >
                <el-form-item>
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="onSaleDialog.orderDetailList"
                        style="width: 100%"
                        :span-method="spanMethod"
                    >
                        <el-table-column
                            v-for="item in onSaleDialog.goodsSpecList"
                            :label="item.pn"
                            :key="item.id"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                {{ scope.row.specValueMap[item.pn] }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            :label="$t('pageKey242', 'SKU编码')"
                            min-width="100"
                            prop="skuSn"
                        />
                        <el-table-column
                            :label="$t('pageKey243', '价格')"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    v-model.number="scope.row.price"
                                    :disabled="onSaleDialog.goods.isCombinationGoods"
                                />
                            </template>
                        </el-table-column>
                        <!--                        <el-table-column
    label="库存"
    width="150"
>
    <template slot-scope="scope">
        <el-input-number
            controls-position="right"
            :min="0"
            v-model.number="scope.row.stock"
        />
    </template>
</el-table-column>-->
                    </el-table>
                    <div
                        class="cell ma-t"
                        v-if="!onSaleDialog.goods.isCombinationGoods">
                        <span class="batch">{{ $t('pageKey244', '批量设置') }}: </span>
                        <template v-for="(item,index) in formField">
                            <!-- TODO vue/no-use-v-if-with-v-for 这不是一个好的解决方案，应该是处理 formField 数据时就过滤掉-->
                            <el-button
                                v-if="!item.hide"
                                :key="index"
                                @click="onBatch(item)"
                            >
                                {{ item.name }}
                            </el-button>
                        </template>
                    </div>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSaleDialogConfirm"
                >
                    {{ $t('pageKey224', '上 架') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onSaleDialogCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
        <!--分销商禁售-->
        <el-dialog
            :title="$t('pageKey245', '分销商禁售')"
            center
            width="1200px"
            :visible.sync="goodsForbidSellDialog.isVisible"
            @closed="onGoodsForbidSellCancel"
        >
            <!-- 内容 -->
            <div class="body">
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAddSelect()"
                    >
                        {{ $t('pageKey246', '选择分销商') }}
                    </el-button>
                </el-button-group>
                <el-table
                    stripe
                    border
                    size="small"
                    :data="goodsForbidSellDialog.tableData"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="distributorLevelName"
                        :label="$t('pageKey247', '分销商等级')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="distributorName"
                        :label="$t('pageKey248', '分销商名称')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="distributorTel"
                        :label="$t('pageKey249', '分销商电话')"
                        min-width="100"
                    />
                    <el-table-column
                        fixed="right"
                        :label="$t('pageKey13', '操作')"
                        width="45"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="onDeleteSelect(scope.row)"
                            >
                                {{ $t('pageKey17', '删除') }}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="goodsForbidSellDialog.pagination"
                        @input="onGoodsForbidSellDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onGoodsForbidSellCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
        <!--选择会员-->
        <el-dialog
            :title="$t('pageKey250', '选择会员')"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="distributorName"
                    :label="$t('pageKey248', '分销商姓名')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.distributorName"
                        :placeholder="$t('pageKey251', '请输入分销商姓名')"
                    />
                </el-form-item>
                <el-form-item
                    prop="distributorTel"
                    :label="$t('pageKey249', '分销商电话')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.distributorTel"
                        :placeholder="$t('pageKey252', '请输入分销商电话')"
                    />
                </el-form-item>
                <el-form-item
                    prop="distributorLevelId"
                    :label="$t('pageKey247', '分销商等级')"
                >
                    <el-select
                        v-model="selectDialog.queryFormModel.distributorLevelId"
                        placeholder="请选择"
                        clearable
                    >
                        <el-option
                            v-for="item in distributorLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="36"
                    />
                    <el-table-column
                        prop="distributorLevelName"
                        :label="$t('pageKey247', '分销商等级')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="distributorName"
                        :label="$t('pageKey248', '分销商名称')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="distributorTel"
                        :label="$t('pageKey249', '分销商电话')"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
        <!--售卖优先级-->
        <el-dialog
            :title="$t('pageKey253', '售卖优先级')"
            center
            width="900px"
            :visible.sync="supplierQuotationsDialog.isVisible"
            @closed="supplierQuotationsDialogCancel"
        >
            <el-form
                ref="supplierQuotationsForm"
                size="small"
                :model="supplierQuotationsDialog.formModel"
                :rules="supplierQuotationsDialog.formRules"
            >
                <el-form-item>
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="supplierQuotationsDialog.supplierQuotationsList"
                        style="width: 100%"
                    >
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="scope.row.skuList"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="skuSn"
                                        :label="$t('pageKey242', 'sku编码')"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="specDesc"
                                        :label="$t('pageKey254', '商品规格')"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="price"
                                        :label="$t('pageKey243', '报价')"
                                        min-width="100"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="stock"
                                        :label="$t('pageKey218', '库存')"
                                        min-width="100"
                                    >
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="supplierName"
                            :label="$t('pageKey103', '供应商')"
                            min-width="100"
                        />
                        <el-table-column
                            prop="warehouseName"
                            :label="$t('pageKey255', '发货仓库')"
                            min-width="60"
                        />
                        <el-table-column
                            prop="stock"
                            :label="$t('pageKey218', '库存')"
                            width="70"
                        />
                        <el-table-column
                            :label="$t('pageKey12', '是否可用')"
                            width="70"
                        >
                            <template slot-scope="scope">
                                <el-switch
                                    v-model="scope.row.isAvailable"
                                    @change="onEditSupplierQuotations(scope.row)"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            :label="$t('pageKey256', '优先级（越大优先级越高）')"
                            width="170"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    v-model.number="scope.row.orderNum"
                                    @change="onEditSupplierQuotations(scope.row)"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!--商品代理-->
        <el-dialog
            :title="$t('pageKey228', '商品代理')"
            center
            width="900px"
            :visible.sync="shopGoodsAgentDialog.isVisible"
            @closed="shopGoodsAgentDialogCancel"
        >
            <el-form
                ref="shopGoodsAgentForm"
                size="small"
                :model="shopGoodsAgentDialog.formModel"
                :rules="shopGoodsAgentDialog.formRules"
            >
                <el-form-item>
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="shopGoodsAgentDialog.shopGoodsAgentList"
                        style="width: 100%"
                        default-expand-all
                    >
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="scope.row.skuList"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="skuSn"
                                        :label="$t('pageKey242', 'sku编码')"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="specDesc"
                                        :label="$t('pageKey254', '商品规格')"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="platPrice"
                                        :label="$t('pageKey257', '平台价格')"
                                        width="80"
                                    />
                                    <el-table-column
                                        prop="showStock"
                                        :label="$t('pageKey218', '库存')"
                                        width="80"
                                    />
                                    <el-table-column
                                        prop="price"
                                        :label="$t('pageKey243', '价格')"
                                        width="150"
                                        v-if="(scope.row.paymentMethod & 1) !== 0"
                                    >
                                        <template slot-scope="scope1">
                                            <el-input-number
                                                controls-position="right"
                                                :min="0.01"
                                                :precision="2"
                                                v-model.number="scope1.row.price"
                                            />
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="pointNum"
                                        :label="$t('pageKey258', '积分')"
                                        width="150"
                                        v-if="(scope.row.paymentMethod & 2) !== 0"
                                    >
                                        <template slot-scope="scope1">
                                            <el-input-number
                                                controls-position="right"
                                                :min="1"
                                                :precision="0"
                                                v-model.number="scope1.row.pointNum"
                                            />
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="shopName"
                            :label="$t('pageKey142', '店铺名称')"
                            min-width="100"
                        />
                        <el-table-column
                            prop="paymentMethod"
                            :label="$t('pageKey171', '付款方式')"
                            width="100"
                        >
                            <template slot-scope="scope">
                                <checkbox-group-andOr
                                    type="paymentMethod"
                                    v-model="scope.row.paymentMethod"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="isAvailable"
                            :label="$t('pageKey12', '是否可用')"
                            width="100"
                        >
                            <template slot-scope="scope">
                                <el-switch
                                    v-model="scope.row.isAvailable"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onShopGoodsAgentConfirm"
                >
                    {{ $t('pageKey73', '保 存') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onShopGoodsAgentCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Goods',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            type: '',
            queryFormModel: {
                goodsName: '', // 商品名称
                goodsSn: '', // 商品货号
                goodsCatId: '', // 商品类目
                supplierId: '', // 供应商
                goodsStatusType: '1', // 商品状态类型
                isPrivateCustom: '',
                goodsSendModeList: '',
                isSetSupplierQuotations: '',
                shopGoodsCatId: '',
            },
            // 表格数据
            tableData: [],
            selectApi: this.$api.Gd.GoodsCat,
            selectApi1: this.$api.Sp.ShopGoodsCat,
            supplierList: [],
            // 文案编辑
            editDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    goodsShareCopy: '',
                },
                // 表单校验规则
                formRules: {},
                // 文案列表
                goodsShareCopyList: [],
                // 素材列表
                goodsMultiStyleList: [],
            },
            // 商品上架
            onSaleDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    orderDetailListJson: '',
                },
                // 表单校验规则
                formRules: {},
                // 商品详情列表
                orderDetailList: [],
                // 商品规格
                goodsSpecList: [],
                goods: {},
            },
            formField: [
                { key: 'price', name: '价格', required: true },
                { key: 'stock', name: '库存', required: true },
                { key: 'id', name: 'id', hide: true },
            ],
            editTab: '1',
            goodsStyleTab: '',
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    mobile: '',
                    distributorName: '',
                    distributorTel: '',
                    distributorLevelId: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                currentTableSelect: [],
            },
            goodsForbidSellDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsId: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
            },
            // 分销等级列表
            distributorLevelList: [],
            // 售卖优先级
            supplierQuotationsDialog: {
                goodsId: '',
                // 对话框显示
                isVisible: false,
                supplierQuotationsList: [],
            },
            // 商品代理
            shopGoodsAgentDialog: {
                goodsId: '',
                // 对话框显示
                isVisible: false,
                shopGoodsAgentList: [],
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.Goods.data({
                ...queryData,
                isPrivateCustom: this.queryFormModel.isPrivateCustom,
            }).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        initSupplierList() {
            this.$api.Gd.Supplier.select({ goodsSendModeList: this.queryFormModel.goodsSendModeList }).then(json => {
                this.supplierList = json.data.data;
            });
        },
        offSaleGoods(row) {
            this.$confirm(this.$t('pageKey259', '是否下架该商品!'), this.$t('pageKey42', '温馨提示!'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Gd.Goods.offSaleGoods({ goodsId: row.id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onOrderNumInput(row) {
            this.$api.Gd.Goods.changeOrderNum({ id: row.id, orderNum: row.orderNum });
        },
        // 扩展信息
        editGoodsExtendInfo(id) {
            this.editDialog.formModel.id = id;
            this.$api.Gd.Goods.getDetail({ id: this.editDialog.formModel.id }).then(json => {
                const res = json.data.data;
                this.editDialog.goodsShareCopyList = JSON.parse(res.goodsShareCopy || null) || [];
                this.initGoodsExtendInfoList();
                // 初始化商品素材设置
                this.$api.Gd.GoodsMultiStyle.initGoodsMultiStyle({ goodsId: this.editDialog.formModel.id }).then(
                    json2 => {
                        const goodsMultiStyleList = json2.data.data;
                        goodsMultiStyleList.forEach(item => {
                            item.goodsImage = item.goodsImage
                                ? item.goodsImage
                                      .split(',')
                                      .map(item2 => ({ name: item2, address: res.basePathUrl + item2 }))
                                : [];
                            item.goodsDetailImage = item.goodsDetailImage
                                ? item.goodsDetailImage
                                      .split(',')
                                      .map(item2 => ({ name: item2, address: res.basePathUrl + item2 }))
                                : [];
                        });
                        this.editDialog.goodsMultiStyleList = goodsMultiStyleList;
                        if (this.editDialog.goodsMultiStyleList.length > 0) {
                            this.goodsStyleTab = this.editDialog.goodsMultiStyleList[0].styleName;
                        }
                    },
                );
                this.editDialog.isVisible = true;
            });
        },
        initGoodsExtendInfoList() {
            const { length } = this.editDialog.goodsShareCopyList;
            for (let num = length; num < 3; num += 1) {
                this.editDialog.goodsShareCopyList.push({
                    copyName: `${this.$t('pageKey260', '文案')} ${num + 1}`,
                    copyContent: '',
                });
            }
        },
        onEditDialogConfirm() {
            const { editForm } = this.$refs;
            editForm.validate().then(() => {
                const goodsMultiStyleList = [];
                this.editDialog.goodsMultiStyleList.forEach(item => {
                    goodsMultiStyleList.push({
                        ...item,
                        goodsImage: item.goodsImage
                            .reduce((prev, curr) => {
                                prev.push(curr.name);
                                return prev;
                            }, [])
                            .join(','),
                        goodsDetailImage: item.goodsDetailImage
                            .reduce((prev, curr) => {
                                prev.push(curr.name);
                                return prev;
                            }, [])
                            .join(','),
                    });
                });
                this.$api.Gd.Goods.editGoods({
                    ...this.editDialog.formModel,
                    goodsShareCopy: JSON.stringify(this.editDialog.goodsShareCopyList),
                    goodsMultiStyleJson: JSON.stringify(goodsMultiStyleList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.editDialog.isVisible = false;
                });
            });
        },
        onEditDialogCancel() {
            const { editForm } = this.$refs;
            editForm.resetFields();
            const { formModel } = this.editDialog;
            formModel.id = '';
            this.editDialog.isVisible = false;
        },
        // 商品上架
        onSaleGoods(row) {
            this.onSaleDialog.formModel.id = row.id;
            this.$api.Gd.Goods.getDetail({ id: this.onSaleDialog.formModel.id }).then(json => {
                const res = json.data.data;
                if (res.skuList.length !== 1) {
                    res.skuList.forEach(item => {
                        const specValueMap = {};
                        item.specDesc.split('_').forEach(specItem => {
                            // eslint-disable-next-line prefer-destructuring
                            specValueMap[specItem.split(':')[0]] = specItem.split(':')[1];
                        });
                        item.specValueMap = specValueMap;
                    });
                }
                this.onSaleDialog.goods = res;
                this.onSaleDialog.orderDetailList = res.skuList;
                this.onSaleDialog.goodsSpecList = JSON.parse(res.specConfig || null) || {};
                this.onSaleDialog.isVisible = true;
            });
        },
        onSaleDialogConfirm() {
            const { onSaleForm } = this.$refs;
            onSaleForm.validate().then(() => {
                this.$api.Gd.Goods.onSaleGoods({
                    goodsId: this.onSaleDialog.formModel.id,
                    orderDetailListJson: JSON.stringify(this.onSaleDialog.orderDetailList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onSaleDialog.isVisible = false;
                    this.onQuery(this.pagination);
                });
            });
        },
        onSaleDialogCancel() {
            const { onSaleForm } = this.$refs;
            onSaleForm.resetFields();
            const { formModel } = this.onSaleDialog;
            formModel.id = '';
            this.onSaleDialog.isVisible = false;
        },
        // eslint-disable-next-line consistent-return
        spanMethod({ rowIndex, columnIndex }) {
            if (columnIndex < this.onSaleDialog.goodsSpecList.length - 1) {
                let colNum = 1;
                for (let j = columnIndex + 1; j < this.onSaleDialog.goodsSpecList.length; j += 1) {
                    colNum *= this.onSaleDialog.goodsSpecList[j].v.length;
                }
                if (rowIndex % colNum === 0) {
                    return {
                        rowspan: colNum,
                        colspan: 1,
                    };
                }
                return {
                    rowspan: 0,
                    colspan: 0,
                };
            }
        },
        onBatch(field) {
            this.$prompt(`${this.$t('pageKey238', '请输入')} ${field.name}`, this.$t('pageKey42', '温馨提示'), {
                inputValidator: val => {
                    if (!val && field.required) {
                        return `${field.name} ${this.$t('pageKey261', '不能为空')}`;
                    }
                    if (/price|stock|costPrice/.test(field.key) && Number.isNaN(val)) {
                        return this.$t('pageKey262', '请填写金额');
                    }
                    return true;
                },
            }).then(({ value }) => {
                if (/price|stock/.test(field.key)) {
                    value = +value;
                }
                this.onSaleDialog.orderDetailList.forEach(item => {
                    item[field.key] = value;
                });
                // const reg = new RegExp(`^${field.key}_.*`);
                // for (const key in this.formModel) {
                //     if (this.formModel.hasOwnProperty(key)) {
                //         if (reg.test(key)) {
                //             this.formModel[key] = value;
                //         }
                //     }
                // }
            });
        },
        // 分销商禁售
        onGoodsForbidSell(row) {
            this.goodsForbidSellDialog.queryFormModel.goodsId = row.id;
            this.$nextTick(() => {
                this.onGoodsForbidSellDialogQuery();
            });
            this.goodsForbidSellDialog.isVisible = true;
        },
        onGoodsForbidSellDialogQuery(pagination) {
            return this.$api.Gd.GoodsForbidSell.data({
                ...this.goodsForbidSellDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.goodsForbidSellDialog.tableData = res.data;
                this.goodsForbidSellDialog.pagination = res.pagination;
            });
        },
        onGoodsForbidSellCancel() {
            this.goodsForbidSellDialog.isVisible = false;
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Mb.Buyer.selectByPage({
                ...this.selectDialog.queryFormModel,
                isDistributor: 1,
                ...pagination,
                goodsForbidSellGoodsIdNE: this.goodsForbidSellDialog.queryFormModel.goodsId,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            const ids = this.selectDialog.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: this.$t('pageKey263', '请选择分销商'),
                    type: 'error',
                });
                return;
            }
            this.$api.Gd.GoodsForbidSell.save({
                goodsIds: this.goodsForbidSellDialog.queryFormModel.goodsId,
                buyerIds: ids.join(','),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onGoodsForbidSellDialogQuery(this.goodsForbidSellDialog.pagination);
                this.selectDialog.isVisible = false;
            });
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelect(row) {
            this.$api.Gd.GoodsForbidSell.delete({ ids: row.id }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onGoodsForbidSellDialogQuery(this.goodsForbidSellDialog.pagination);
            });
        },
        initDistributorLevelList() {
            this.$api.Mb.DistributorLevel.select().then(json => {
                this.distributorLevelList = json.data.data;
            });
        },
        onSupplierQuotations(row) {
            this.supplierQuotationsDialog.goodsId = row.id;
            this.getSupplierQuotationsList();
        },
        getSupplierQuotationsList() {
            this.$api.Gd.SupplierQuotations.getGoodsSupplierQuotations({
                goodsId: this.supplierQuotationsDialog.goodsId,
            }).then(json => {
                const res = json.data.data;
                this.supplierQuotationsDialog.supplierQuotationsList = res;
                this.supplierQuotationsDialog.isVisible = true;
            });
        },
        supplierQuotationsDialogCancel() {
            const { supplierQuotationsForm } = this.$refs;
            supplierQuotationsForm.resetFields();
            this.supplierQuotationsDialog.isVisible = false;
        },
        onEditSupplierQuotations(row) {
            this.$api.Gd.SupplierQuotations.onEditSupplierQuotations({
                id: row.id,
                isAvailable: row.isAvailable,
                orderNum: row.orderNum,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
                this.getSupplierQuotationsList();
            });
        },
        exportExcel() {
            this.$http.href('/gd/goods/exportExcel', {
                ...this.queryFormModel,
            });
        },
        // 商品代理
        onShopGoodsAgent(row) {
            this.shopGoodsAgentDialog.goodsId = row.id;
            this.getShopGoodsAgentList();
        },
        getShopGoodsAgentList() {
            this.$api.Gd.GoodsAgent.getShopGoodsAgentList({
                goodsId: this.shopGoodsAgentDialog.goodsId,
            }).then(json => {
                const res = json.data.data;
                this.shopGoodsAgentDialog.shopGoodsAgentList = res;
                this.shopGoodsAgentDialog.isVisible = true;
            });
        },
        onShopGoodsAgentConfirm() {
            this.$api.Gd.GoodsAgent.saveShopGoodsAgent({
                goodsId: this.shopGoodsAgentDialog.goodsId,
                agentJson: JSON.stringify(this.shopGoodsAgentDialog.shopGoodsAgentList),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.shopGoodsAgentDialog.isVisible = false;
            });
        },
        onShopGoodsAgentCancel() {
            this.shopGoodsAgentDialog.isVisible = false;
        },
        onSyncGoods() {
            this.$confirm(this.$t('pageKey264', '是否确定要同步商品!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Rs.Pospal.syncGoods({}).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        // 复制快递产品
        onCopyGoods(row) {
            this.$confirm(this.$t('pageKey265', '是否确定要复制到快递产品!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Gd.Goods.copyGoods({ ids: row.id, goodsSendMode: 1 }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                });
            });
        },
        onSyncInventoryStock() {
            this.$confirm(this.$t('pageKey266', '是否确定要同步进销存库存!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Rs.Inventory.syncInventoryStock().then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onSyncInventoryGoods() {
            this.$confirm(this.$t('pageKey267', '是否确定要同步进销存商品!'), this.$t('pageKey42', '温馨提示'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Gd.Inventory.syncInventoryGoods().then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
    created() {
        if (this.$route.name === 'cgd-goods-customGoods') {
            // 定制商品
            this.queryFormModel.isPrivateCustom = 1;
            this.type = 2;
        } else {
            // 普通商品
            this.queryFormModel.isPrivateCustom = 0;
            if (this.$route.name === 'gd-goods-goods') {
                this.type = 1;
                this.queryFormModel.goodsSendModeList = '1,5';
            } else if (this.$route.name === 'gd-goods-bondedGoods') {
                this.type = 3;
                this.queryFormModel.goodsSendModeList = '4';
            } else if (this.$route.name === 'gd-goods-communityGoods') {
                this.type = 5;
                this.queryFormModel.goodsSendModeList = '6';
            } else {
                this.type = 4;
                this.queryFormModel.goodsSendModeList = '2';
            }
        }

        this.initSupplierList();
        this.initDistributorLevelList();
    },
};
</script>

<style lang="scss">
</style>
