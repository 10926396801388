<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 内容 -->
        <el-form
            ref="form"
            slot="body"
            label-width="10em"
            size="small"
        >
            <el-card
                size="small"
                shadow="never"
                header="商品信息"
            >
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    {{ goods.goodsName }}
                </el-form-item>
                <el-form-item
                    prop="goodsShortName"
                    label="商品简称"
                >
                    {{ goods.goodsShortName }}
                </el-form-item>
                <el-form-item
                    prop="goodsSubtitle"
                    label="商品副标题"
                >
                    {{ goods.goodsSubtitle }}
                </el-form-item>
                <el-form-item
                    prop="price"
                    label="商品价格"
                >
                    {{ goods.price }}
                </el-form-item>
                <el-form-item
                    prop="marketPrice"
                    label="商品划线价"
                >
                    {{ goods.marketPrice }}
                </el-form-item>
            </el-card>
            <el-card
                size="small"
                shadow="never"
                class="ma-t"
            >
                <el-table
                    stripe
                    border
                    size="small"
                    :data="shopGoodsAgentList"
                    style="width: 100%"
                    default-expand-all
                >
                    <!--                        <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="scope.row.skuList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="skuSn"
                                label="sku编码"
                                min-width="100"
                            />
                            <el-table-column
                                prop="specDesc"
                                label="商品规格"
                                min-width="100"
                            />
                            <el-table-column
                                prop="platPrice"
                                label="平台价格"
                                width="80"
                            />
                            <el-table-column
                                prop="showStock"
                                label="库存"
                                width="80"
                            />
                            <el-table-column
                                prop="price"
                                label="价格"
                                width="150"
                                v-if="(scope.row.paymentMethod & 1) !== 0"
                            >
                                <template slot-scope="scope1">
                                    <el-input-number
                                        controls-position="right"
                                        :min="0.01"
                                        :precision="2"
                                        v-model.number="scope1.row.price"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="pointNum"
                                label="积分"
                                width="150"
                                v-if="(scope.row.paymentMethod & 2) !== 0"
                            >
                                <template slot-scope="scope1">
                                    <el-input-number
                                        controls-position="right"
                                        :min="1"
                                        :precision="0"
                                        v-model.number="scope1.row.pointNum"
                                    />
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>-->
                    <!--                    <el-table-column
                                            prop="isAvailable"
                                            label="是否可用"
                                            width="70"
                                        >
                                            <template slot-scope="scope">
                                                {{ scope.row.isAvailable ? '是' : '否' }}
                                            </template>
                                        </el-table-column>-->
                    <el-table-column
                        prop="storeName"
                        label="门店名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="agentGoodsName"
                        label="商品名称"
                        width="200"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="price"
                        label="价格"
                        width="200"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="stock"
                        label="库存"
                        width="200"
                    >
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>

        <div class="ta-c pa-a">
            <el-button
                size="small"
                @click="$router.back()"
            >
                返 回
            </el-button>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'AddGoods',
    mixins: [pagesMixin],
    data() {
        return {
            shopGoodsAgentList: [],
            goods: {},
        };
    },
    computed: {
        goodsId() {
            return this.$route.params.goodsId;
        },
        formField() {
            const list = [];
            list.push({ key: 'price', name: '价格', required: true });
            list.push({ key: 'agentGoodsName', name: '商品名称', required: true });
            list.push({ key: 'stock', name: '库存', required: true });
            return list;
        },
    },
    methods: {
        onSave() {
            this.$api.Sp.ShopStoreGoods.saveShopStoreGoodsBatchAgent({
                goodsId: this.goodsId,
                agentJson: JSON.stringify(this.shopGoodsAgentList),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
            });
        },
        init() {
            this.$api.Sp.ShopStoreGoods.getShopStoreGoodsAgentList({
                goodsId: this.goodsId,
            }).then(json => {
                this.shopGoodsAgentList = json.data.data;
                this.shopGoodsAgentList.forEach(item => {
                    if (!item.stock) {
                        item.stock = undefined;
                    }
                    if (!item.price) {
                        item.price = undefined;
                    }
                });
            });
            this.$api.Gd.Goods.getDetail({ id: this.goodsId }).then(json => {
                this.goods = json.data.data;
            });
        },
        onBatch(field) {
            this.$prompt(`请输入${field.name}`, '温馨提示', {
                inputValidator(val) {
                    if (!val && field.required) {
                        return `${field.name}不能为空`;
                    }
                    if (/price|stock|agentGoodsName/.test(field.key) && Number.isNaN(val)) {
                        return `请填写`;
                    }
                    return true;
                },
            }).then(({ value }) => {
                if (/price|stock|agentGoodsName/.test(field.key)) {
                    value = +value;
                }
                this.shopGoodsAgentList.forEach(item => {
                    if (field.key === 'taxRate') {
                        this.$set(item, field.key, +value);
                    } else {
                        this.$set(item, field.key, value);
                    }
                });
            });
        },
    },
    created() {
        this.init();
    },
};
</script>

<style lang="scss">
</style>
