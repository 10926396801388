<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsSn"
                    label="商品货号"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        placeholder="请输入商品货号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatId"
                    label="商品类目"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="queryFormModel.goodsCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="supplierId"
                    label="供应商"
                >
                    <el-select
                        v-model="queryFormModel.supplierId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in supplierList"
                            :key="item.id"
                            :label="item.supplierName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="skuSn"
                    label="sku编码"
                >
                    <el-input
                        v-model="queryFormModel.skuSn"
                        placeholder="请输入sku编码"
                    />
                </el-form-item>
                <el-form-item
                    prop="specDesc"
                    label="sku规格"
                >
                    <el-input
                        v-model="queryFormModel.specDesc"
                        placeholder="请输入sku规格"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onBatchSetAlarmStock"
                        :disabled="!currentTableSelect.length"
                    >
                        批量设置库存预警
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    label="商品图片"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.specImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsSn"
                    label="商品货号"
                    min-width="60"
                />
                <el-table-column
                    prop="goodsCatName"
                    label="商品类目"
                    min-width="60"
                />
                <el-table-column
                    prop="skuSn"
                    label="sku编码"
                    min-width="60"
                />
                <el-table-column
                    prop="specDesc"
                    label="sku规格"
                    min-width="60"
                />
                <el-table-column
                    prop="supplierName"
                    label="供应商名称"
                    min-width="70"
                    v-if="(userData.appId === 1 && !userData.platform.isSupplierQuotations) || userData.appId === 2"
                />
                <el-table-column
                    prop="stock"
                    label="库存"
                    min-width="50"
                    v-if="(userData.appId === 1 && !userData.platform.isSupplierQuotations) || userData.appId === 2"
                />
                <el-table-column
                    prop="goodsStatusName"
                    label="商品状态"
                    min-width="60"
                />
                <el-table-column
                    prop="quotationsStock"
                    label="供应商总库存"
                    width="100"
                    v-if="userData.appId === 1 && (userData.platform && userData.platform.isSupplierQuotations)"
                />
                <el-table-column
                    prop="supplierQuotationsStock"
                    label="供应商库存"
                    min-width="100"
                    v-if="userData.appId === 1 && (userData.platform && userData.platform.isSupplierQuotations)"
                />
                <el-table-column
                    prop="alarmStock"
                    label="预警库存"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :max="999999"
                            v-model.number="scope.row.alarmStock"
                            @change="onAlarmStockInput(scope.row)"
                        />
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Goods',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            type: '',
            queryFormModel: {
                goodsName: '',
                goodsSn: '',
                goodsCatId: '',
                supplierId: '',
                skuSn: '',
                specDesc: '',
                userFilter: '1',
            },
            // 表格数据
            tableData: [],
            selectApi: this.$api.Gd.GoodsCat,
            supplierList: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.Goods.findSkuMapList({
                ...queryData,
            }).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onAlarmStockInput(row) {
            this.$api.Gd.Goods.changeAlarmStock({ skuIds: row.id, alarmStock: row.alarmStock });
        },
        onBatchSetAlarmStock() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择数据',
                    type: 'error',
                });
                return;
            }
            this.$prompt(`请输入库存预警`, '温馨提示').then(({ value }) => {
                this.$api.Gd.Goods.changeAlarmStock({ skuIds: ids.join(','), alarmStock: value }).then(() => {
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
