<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.goodsStatusType"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    :label="$t('pageKey199', '出售中')"
                    name="1"
                />
                <el-tab-pane
                    :label="$t('pageKey200', '已售罄')"
                    name="2"
                />
                <el-tab-pane
                    :label="$t('pageKey201', '仓库中')"
                    name="3"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品货号')"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        :placeholder="$t('pageKey203', '请输入商品货号')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        :placeholder="$t('pageKey205', '请输入商品名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatId"
                    :label="$t('pageKey206', '商品类目')"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="queryFormModel.goodsCatId"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    prop="storeId"
                    :label="$t('pageKey620', '门店')"
                >
                    <RemoteSelect
                        filterable
                        clearable
                        remote="/sp/shopStore/select"
                        :placeholder="$t('pageKey101', '请选择')"
                        :props="{id:'id',name:'storeName'}"
                        v-model="queryFormModel.storeId"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                :empty-text="$t('pageKey977', '暂无数据')"
            >
                <el-table-column
                    prop="id"
                    label="ID"
                    width="50"
                />
                <el-table-column
                    prop="storeName"
                    :label="$t('pageKey620', '门店')"
                    min-width="100"
                />
                <el-table-column
                    :label="$t('pageKey215', '商品图片')"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.mainImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品货号')"
                    min-width="70"
                />
                <el-table-column
                    prop="goodsCatName"
                    :label="$t('pageKey206', '商品类目')"
                    min-width="70"
                />
                <el-table-column
                    prop="price"
                    :label="$t('pageKey216', '销售价')"
                    min-width="60"
                    v-if="userData.appId !== 1 || (userData.platform && !userData.platform.isSupplierQuotations)"
                />
                <el-table-column
                    prop="marketPrice"
                    :label="$t('pageKey217', '市场价')"
                    min-width="60"
                />
                <el-table-column
                    prop="stock"
                    :label="$t('pageKey218', '库存')"
                    min-width="50"
                    v-if="userData.appId !== 1 || (userData.platform && !userData.platform.isSupplierQuotations)"
                >
                    <template slot-scope="scope">
                        {{ scope.row.stock }} ({{ scope.row.realStock }})
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsStatusName"
                    :label="$t('pageKey219', '商品状态')"
                    min-width="70"
                />
                <el-table-column
                    prop="orderNum"
                    :label="$t('pageKey222', '排序')"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-input-number
                            size="mini"
                            controls-position="right"
                            :min="0"
                            :max="999999"
                            v-model.number="scope.row.orderNum"
                            @change="onOrderNumInput(scope.row)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    :label="$t('pageKey13', '操作')"
                    width="180"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/goods/addStoreGoods/${scope.row.id}`)"
                        >
                            {{ $t('pageKey16', '编辑') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            v-if="scope.row.goodsStatus == 1 || scope.row.goodsStatus == 4"
                            @click="onSaleGoods(scope.row)"
                        >
                            {{ $t('pageKey224', '上架') }}
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            v-if="scope.row.goodsStatus == 3 || (scope.row.goodsStatus == 4 && scope.row.offShelvesReason == 2)"
                            @click="offSaleGoods(scope.row)"
                        >
                            {{ $t('pageKey225', '下架') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!--商品上架-->
        <el-dialog
            :title="$t('pageKey241', '商品上架')"
            center
            width="1200px"
            :visible.sync="onSaleDialog.isVisible"
            @closed="onSaleDialogCancel"
        >
            <el-form
                ref="onSaleForm"
                size="small"
                :model="onSaleDialog.formModel"
                :rules="onSaleDialog.formRules"
            >
                <el-form-item>
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="onSaleDialog.orderDetailList"
                        style="width: 100%"
                        :span-method="spanMethod"
                    >
                        <el-table-column
                            v-for="item in onSaleDialog.goodsSpecList"
                            :label="item.pn"
                            :key="item.id"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                {{ scope.row.specValueMap[item.pn] }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            :label="$t('pageKey242', 'SKU编码')"
                            min-width="100"
                            prop="skuSn"
                        />
                        <el-table-column
                            :label="$t('pageKey243', '价格')"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    v-model.number="scope.row.price"
                                    :disabled="onSaleDialog.goods.isCombinationGoods"
                                />
                            </template>
                        </el-table-column>
                        <!--                        <el-table-column
    label="库存"
    width="150"
>
    <template slot-scope="scope">
        <el-input-number
            controls-position="right"
            :min="0"
            v-model.number="scope.row.stock"
        />
    </template>
</el-table-column>-->
                    </el-table>
                    <div
                        class="cell ma-t"
                        v-if="!onSaleDialog.goods.isCombinationGoods">
                        <span class="batch">{{ $t('pageKey244', '批量设置') }}: </span>
                        <template v-for="(item,index) in formField">
                            <!-- TODO vue/no-use-v-if-with-v-for 这不是一个好的解决方案，应该是处理 formField 数据时就过滤掉-->
                            <el-button
                                v-if="!item.hide"
                                :key="index"
                                @click="onBatch(item)"
                            >
                                {{ item.name }}
                            </el-button>
                        </template>
                    </div>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSaleDialogConfirm"
                >
                    {{ $t('pageKey224', '上 架') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onSaleDialogCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Goods',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            type: '',
            queryFormModel: {
                goodsName: '', // 商品名称
                goodsSn: '', // 商品货号
                goodsCatId: '', // 商品类目
                supplierId: '', // 供应商
                goodsStatusType: '1', // 商品状态类型
                isPrivateCustom: '',
                goodsSendModeList: '',
                isSetSupplierQuotations: '',
                shopGoodsCatId: '',
                isStoreGoods: 1,
                storeId: '',
            },
            // 表格数据
            tableData: [],
            selectApi: this.$api.Gd.GoodsCat,
            // 商品上架
            onSaleDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    orderDetailListJson: '',
                },
                // 表单校验规则
                formRules: {},
                // 商品详情列表
                orderDetailList: [],
                // 商品规格
                goodsSpecList: [],
                goods: {},
            },
            formField: [
                { key: 'price', name: '价格', required: true },
                { key: 'stock', name: '库存', required: true },
                { key: 'id', name: 'id', hide: true },
            ],
            editTab: '1',
            goodsStyleTab: '',
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.Goods.data({
                ...queryData,
                isPrivateCustom: this.queryFormModel.isPrivateCustom,
            }).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        offSaleGoods(row) {
            this.$confirm(this.$t('pageKey259', '是否下架该商品!'), this.$t('pageKey42', '温馨提示!'), {
                type: 'warning',
                confirmButtonText: this.$t('pageKey19', '确定'),
                cancelButtonText: this.$t('pageKey20', '取消'),
            }).then(() => {
                this.$api.Gd.Goods.offSaleGoods({ goodsId: row.id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onOrderNumInput(row) {
            this.$api.Gd.Goods.changeOrderNum({ id: row.id, orderNum: row.orderNum });
        },
        // 商品上架
        onSaleGoods(row) {
            this.onSaleDialog.formModel.id = row.id;
            this.$api.Gd.Goods.getDetail({ id: this.onSaleDialog.formModel.id }).then(json => {
                const res = json.data.data;
                if (res.skuList.length !== 1) {
                    res.skuList.forEach(item => {
                        const specValueMap = {};
                        item.specDesc.split('_').forEach(specItem => {
                            // eslint-disable-next-line prefer-destructuring
                            specValueMap[specItem.split(':')[0]] = specItem.split(':')[1];
                        });
                        item.specValueMap = specValueMap;
                    });
                }
                this.onSaleDialog.goods = res;
                this.onSaleDialog.orderDetailList = res.skuList;
                this.onSaleDialog.goodsSpecList = JSON.parse(res.specConfig || null) || {};
                this.onSaleDialog.isVisible = true;
            });
        },
        onSaleDialogConfirm() {
            const { onSaleForm } = this.$refs;
            onSaleForm.validate().then(() => {
                this.$api.Gd.Goods.onSaleGoods({
                    goodsId: this.onSaleDialog.formModel.id,
                    orderDetailListJson: JSON.stringify(this.onSaleDialog.orderDetailList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onSaleDialog.isVisible = false;
                    this.onQuery(this.pagination);
                });
            });
        },
        onSaleDialogCancel() {
            const { onSaleForm } = this.$refs;
            onSaleForm.resetFields();
            const { formModel } = this.onSaleDialog;
            formModel.id = '';
            this.onSaleDialog.isVisible = false;
        },
        // eslint-disable-next-line consistent-return
        spanMethod({ rowIndex, columnIndex }) {
            if (columnIndex < this.onSaleDialog.goodsSpecList.length - 1) {
                let colNum = 1;
                for (let j = columnIndex + 1; j < this.onSaleDialog.goodsSpecList.length; j += 1) {
                    colNum *= this.onSaleDialog.goodsSpecList[j].v.length;
                }
                if (rowIndex % colNum === 0) {
                    return {
                        rowspan: colNum,
                        colspan: 1,
                    };
                }
                return {
                    rowspan: 0,
                    colspan: 0,
                };
            }
        },
        onBatch(field) {
            this.$prompt(`${this.$t('pageKey238', '请输入')} ${field.name}`, this.$t('pageKey42', '温馨提示'), {
                inputValidator: val => {
                    if (!val && field.required) {
                        return `${field.name} ${this.$t('pageKey261', '不能为空')}`;
                    }
                    if (/price|stock|costPrice/.test(field.key) && Number.isNaN(val)) {
                        return this.$t('pageKey262', '请填写金额');
                    }
                    return true;
                },
            }).then(({ value }) => {
                if (/price|stock/.test(field.key)) {
                    value = +value;
                }
                this.onSaleDialog.orderDetailList.forEach(item => {
                    item[field.key] = value;
                });
                // const reg = new RegExp(`^${field.key}_.*`);
                // for (const key in this.formModel) {
                //     if (this.formModel.hasOwnProperty(key)) {
                //         if (reg.test(key)) {
                //             this.formModel[key] = value;
                //         }
                //     }
                // }
            });
        },
    },
};
</script>

<style lang="scss">
</style>
