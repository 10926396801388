<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 内容 -->
        <el-form
            ref="form"
            slot="body"
            label-width="10em"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-card
                size="small"
                shadow="never"
                :header="$t('pageKey269', '基本信息')"
            >
                <el-form-item
                    prop="goodsType"
                    :label="$t('pageKey268', '商品类型')"

                >
                    <RemoteSelect
                        remote="/sp/shop/getShopAvailableGoodsType"
                        :placeholder="$t('pageKey238', '请输入')"
                        v-model="formModel.goodsType"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                >
                    <el-input
                        :placeholder="$t('pageKey238', '请输入')"
                        v-model="formModel.goodsName"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsShortName"
                    :label="$t('pageKey270', '商品简称')"
                >
                    <el-input
                        :placeholder="$t('pageKey238', '请输入')"
                        v-model="formModel.goodsShortName"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSubtitle"
                    :label="$t('pageKey237', '商品副标题')"
                >
                    <el-input
                        :placeholder="$t('pageKey238', '请输入')"
                        v-model="formModel.goodsSubtitle"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsKeywords"
                    :label="$t('pageKey271', '关键词')"
                >
                    <el-input
                        type="textarea"
                        :placeholder="$t('pageKey238', '请输入')"
                        v-model="formModel.goodsKeywords"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsImage"
                    :label="type === 1? $t('pageKey215', '商品列表图') : $t('pageKey272', '商品主图')"
                >
                    <upload
                        draggable
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="type === 1? 100 : 1"
                        :tips="type === 1? $t('pageKey273', '建议尺寸：800*800像素'):$t('pageKey273', '建议尺寸：800*800像素')"
                        v-model="formModel.goodsImage"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsDetailImage"
                    :label="$t('pageKey240', '商品详情图')"
                >
                    <upload
                        draggable
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :tips=" $t('pageKey273', '建议尺寸：800*800像素')"
                        v-model="formModel.goodsDetailImage"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsVideo"
                    :label="$t('pageKey274', '商品视频')"
                >
                    <upload
                        draggable
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="formModel.goodsVideo"
                        accept="video/*"
                        tips=""
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsDescription"
                    label="商品描述"
                >
                    <el-input
                        type="textarea"
                        v-model="formModel.goodsDescription"
                        auto-complete="off"
                    />
                </el-form-item>
                <!--<el-form-item
v-if="type === 2"
prop="customFrontImage"
label="商品定制正面图"
>
<upload
draggable
validate-md5
action="/rs/attachment/uploadFileLibrary"
:limit="1"
tips=""
v-model="formModel.customFrontImage"
/>
</el-form-item>
<el-form-item
v-if="type === 2"
prop="customBackImage"
label="商品定制反面图"
>
<upload
draggable
validate-md5
action="/rs/attachment/uploadFileLibrary"
:limit="1"
tips=""
v-model="formModel.customBackImage"
/>
</el-form-item>-->
                <el-form-item
                    v-if="type === 2"
                    prop="customDefaultImage"
                    :label="$t('pageKey275', '商品定制模板图')"
                >
                    <upload
                        draggable
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="20"
                        tips=""
                        v-model="formModel.customDefaultImage"
                    />
                </el-form-item>
                <el-form-item
                    v-if="type === 2"
                    prop="customCombineConfig"
                    :label="$t('pageKey276', '商品定制合成图配置')"
                >
                    <el-input
                        type="textarea"
                        v-model="formModel.customCombineConfig"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="storeInitGoodsCatId"
                    :label="$t('pageKey277', '门店初始商品类目')"
                    v-if="$power(['goods:storeInitGoodsCat'])"
                >
                    <RemoteSelect
                        multiple
                        remote="/sp/storeInitGoodsCat/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'goodsCatName'}"
                        v-model="formModel.storeInitGoodsCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopGoodsCatIds"
                    :label="$t('pageKey207', '店铺类目')"
                    v-if="userData.appId === 2"
                >
                    <TreePicker
                        remote="/sp/shopGoodsCat/selectAll"
                        v-model="formModel.shopGoodsCatIds"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopStoreGoodsCatId"
                    :label="$t('pageKey278', '门店类目')"
                    v-if="userData.appId === 3"
                >
                    <RemoteSelect
                        multiple
                        remote="/sp/shopStoreGoodsCat/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'goodsCatName'}"
                        v-model="formModel.shopStoreGoodsCatId"
                    />
                </el-form-item>
                <el-form-item
                    :label="$t('pageKey206', '商品后台类目')"
                    prop="goodsCatId"
                >
                    <CascaderPicker
                        :api-class="GoodsCatApi"
                        v-model="formModel.goodsCatId"
                        :placeholder="$t('pageKey101', '请选择')"
                    />
                </el-form-item>
                <el-form-item
                    prop="supplierId"
                    :label="$t('pageKey103', '供应商')"
                    v-if="(userData.appId === 1 && !userData.platform.isSupplierQuotations) || (userData.appId === 2 && !formModel.storeId) || formModel.isCombinationGoods"
                >
                    <RemoteSelect
                        remote="/gd/supplier/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'supplierName'}"
                        v-model="formModel.supplierId"
                        :remote-params="{goodsSendModeList:goodsSendModeList}"
                        @change="changeSupplier"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSendMode"
                    :label="$t('pageKey113', '商品发货方式')"
                    v-if="(userData.appId === 1 && userData.platform.isSupplierQuotations) && goodsSendModeList.length !== 1"
                >
                    <dictionaries-picker
                        type="goodsSendMode"
                        v-model="formModel.goodsSendMode"
                        :exclude="excludeGoodsSendModeList"
                        @change="changeGoodsSendMode"
                        :clearable="false"
                    />
                </el-form-item>
                <el-form-item
                    key="freightTemplateId"
                    prop="freightTemplateId"
                    :label="$t('pageKey279', '运费模板')"
                    v-if="(userData.shop && userData.shop.freightMode == 3 && formModel.goodsSendMode !== 6) || userData.appId === 1"
                >
                    <RemoteSelect
                        remote="/sp/shopFreightTemplate/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'templateName'}"
                        v-model="formModel.freightTemplateId"
                    />
                </el-form-item>
                <el-form-item
                    key="premiumTemplateId"
                    prop="premiumTemplateId"
                    :label="$t('pageKey280', '保费模板')"
                    v-if="userData.shop && (userData.shop.goodsConfig & 1) != 0"
                >
                    <RemoteSelect
                        clearable
                        remote="/sp/shopPremiumTemplate/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'templateName'}"
                        v-model="formModel.premiumTemplateId"
                    />
                </el-form-item>
                <el-form-item
                    prop="aftersaleTemplateId"
                    :label="$t('pageKey281', '售后模板')"
                    v-if="userData.appId !== 3"
                >
                    <RemoteSelect
                        clearable
                        remote="/sp/shopAftersaleTemplate/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'templateName'}"
                        v-model="formModel.aftersaleTemplateId"
                    />
                </el-form-item>
                <el-form-item
                    prop="gstRate"
                    :label="$t('pageKey282', 'GST比例(%)')"
                    v-if="userData.shop && userData.shop.goodsSendModes.split(',').includes('2') && userData.appId !== 3"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="formModel.gstRate"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopStoreLevelId"
                    :label="$t('pageKey283', '门店等级')"
                    v-if="userData.appId === 2"
                >
                    <RemoteSelect
                        multiple
                        remote="/sp/shopStoreLevel/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'levelName'}"
                        v-model="formModel.shopStoreLevelId"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsLabelId"
                    :label="$t('pageKey284', '商品标签')"
                    v-if="userData.appId === 2"
                >
                    <RemoteSelect
                        multiple
                        remote="/gd/goodsLabel/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'labelName'}"
                        v-model="formModel.goodsLabelId"
                    />
                </el-form-item>
                <el-form-item
                    prop="taxRate"
                    :label="$t('pageKey409', '税率')"
                >
                    <el-select
                        clearable
                        v-model="formModel.taxRate"
                        :placeholder="$t('pageKey101', '请选择')"
                    >
                        <el-option
                            key="0"
                            label="0%"
                            :value="0"
                        />
                        <el-option
                            key="5"
                            label="6.3%"
                            :value="6.3"
                        />
                        <el-option
                            key="1"
                            label="9.1%"
                            :value="9.1"
                        />
                        <el-option
                            key="2"
                            label="23.1%"
                            :value="23.1"
                        />
                        <el-option
                            key="3"
                            label="29%"
                            :value="29"
                        />
                    </el-select>
                </el-form-item>
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
                :header="$t('pageKey285', '价格库存')"
            >
                <el-form-item
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品货号')"
                >
                    <RemoteSelect
                        remote="/gd/goods/findThirdPlatformSkuSnCache"
                        filterable
                        clearable
                        :allow-create="(name)=>{return {'goodsSn':name,'name':name}}"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'goodsSn',name:'selectName'}"
                        v-model="formModel.goodsSn"
                    />
                </el-form-item>
                <el-form-item
                    prop="isCombinationGoods"
                    :label="$t('pageKey286', '是否组合产品')"
                    v-if="$power(['goodsAuthority:combinationGoods'])"
                >
                    <el-switch
                        v-model="formModel.isCombinationGoods"
                        @change="changeIsCombinationGoods"
                    />
                </el-form-item>
                <!--                <el-form-item
                                    prop="isCombinationSplit"
                                    label="组合产品下单拆分"
                                    v-if="formModel.isCombinationGoods"
                                >
                                    <el-switch
                                        v-model="formModel.isCombinationSplit"
                                    />
                                </el-form-item>-->
                <el-form-item
                    :label="$t('pageKey287', '组合商品列表')"
                    v-if="formModel.isCombinationGoods"
                >
                    <div
                        class="ma-b"
                    >
                        <el-button-group>
                            <el-button
                                type="success"
                                icon="el-icon-plus"
                                size="small"
                                @click="onAddSelectGoods"
                                :disabled="!formModel.goodsSendMode || !formModel.supplierId"
                            >
                                {{ $t('pageKey288', '选择多规格商品') }}
                            </el-button>
                            <el-button
                                type="success"
                                icon="el-icon-plus"
                                size="small"
                                @click="onAddSelectSku"
                                :disabled="!formModel.goodsSendMode || !formModel.supplierId"
                            >
                                {{ $t('pageKey289', '选择单规格商品') }}
                            </el-button>
                        </el-button-group>
                    </div>
                    <!-- 表格 -->
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="combinationGoodsList"
                        style="width: 100%"
                    >
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="scope.row.skuList"
                                    style="width: 100%"
                                    v-if="scope.row.skuList"
                                >
                                    <el-table-column
                                        prop="skuSn"
                                        :label="$t('pageKey242', 'sku编码')"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="specDesc"
                                        :label="$t('pageKey254', '商品规格')"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="price"
                                        :label="$t('pageKey243', '商品价格')"
                                        width="80"
                                    />
                                    <el-table-column
                                        prop="addPrice"
                                        :label="$t('pageKey290', '加价')"
                                        width="150"
                                    >
                                        <template slot-scope="scope1">
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                :precision="2"
                                                v-model.number="scope1.row.addPrice"
                                            />
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="goodsCatName"
                            :label="$t('pageKey206', '商品分类')"
                            min-width="100"
                        />
                        <el-table-column
                            prop="goodsName"
                            :label="$t('pageKey204', '商品名称')"
                            min-width="100"
                        />
                        <el-table-column
                            prop="goodsSn"
                            :label="$t('pageKey202', '商品编号')"
                            min-width="100"
                        />
                        <el-table-column
                            prop="skuSn"
                            :label="$t('pageKey242', 'sku编码')"
                            min-width="100"
                        />
                        <el-table-column
                            prop="specDesc"
                            :label="$t('pageKey291', 'sku规格')"
                            min-width="100"
                        />
                        <el-table-column
                            :label="$t('pageKey243', '价格')"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    v-model.number="scope.row.combinationPrice"
                                    @change="changeGoodsPrice"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            :label="$t('pageKey292', '数量')"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="1"
                                    :precision="0"
                                    v-model.number="scope.row.combinationQuantity"
                                    @change="changeGoodsPrice"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            :label="$t('pageKey13', '操作')"
                            width="45"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onDeleteSelectItem(combinationGoodsList, scope.row)"
                                >
                                    {{ $t('pageKey17', '删除') }}
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item
                    prop="goodsSpecList"
                    :label="$t('pageKey254', '商品规格')"
                    v-if="!formModel.isCombinationGoods && (!formModel.id || formModel.goodsSpecList.length !== 0)"
                >
                    <GoodsSpu
                        ref="GoodsSpu"
                        v-model="formModel.goodsSpecList"
                        :goods-image-base-url="formModel.goodsImageBaseUrl"
                    />
                </el-form-item>
                <el-form-item
                    v-if="hasGoodsSpecList && !formModel.isCombinationGoods"
                    prop="goodsSpecList"
                    :label="$t('pageKey293', '规格明细')"
                >
                    <GoodsSpec
                        ref="GoodsSpec"
                        :spu-data="formModel.goodsSpecList"
                        :goods-sn="formModel.goodsSn"
                        v-model="formModel.skuSpecList"
                    />
                </el-form-item>
                <template v-if="!hasGoodsSpecList">
                    <el-form-item
                        prop="stock"
                        :label="$t('pageKey218', '库存')"
                        v-if="userData.appId !== 1 || (userData.platform && !userData.platform.isSupplierQuotations)"
                    >
                        <el-input
                            type="number"
                            :placeholder="$t('pageKey238', '请输入')"
                            v-model="formModel.stock"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="weight"
                        :label="$t('pageKey294', '物流重量')"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.weight"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="grossWeight"
                        :label="$t('pageKey295', '毛重')"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.grossWeight"
                        />
                    </el-form-item>
                    <!--<el-form-item
prop="netWeight"
label="净重">
<el-input-number
controls-position="right"
:min="0"
:precision="2"
v-model.number="formModel.netWeight"/>
</el-form-item>-->
                    <el-form-item
                        prop="price"
                        :label="$t('pageKey243', '价格')"
                    >
                        <el-input
                            type="number"
                            :placeholder="$t('pageKey238', '请输入')"
                            v-model="formModel.price"
                            :disabled="formModel.isCombinationGoods"
                        />
                    </el-form-item>
                </template>
                <el-form-item
                    prop="marketPrice"
                    :label="$t('pageKey296', '划线价')"
                >
                    <el-input
                        type="number"
                        :placeholder="$t('pageKey238', '请输入')"
                        v-model="formModel.marketPrice"
                    />
                </el-form-item>
                <el-form-item
                    prop="isShowMarketPrice"
                    :label="$t('pageKey297', '是否显示划线价')"
                >
                    <el-switch v-model="formModel.isShowMarketPrice"/>
                </el-form-item>
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
                :header="$t('pageKey298', '商品设置')"
                v-if="userData.appId !== 3"
            >
                <el-form-item
                    :label="$t('pageKey299', '计划上架时间')"
                    label-width="9em"
                >
                    <el-date-picker
                        v-model="formModel.planOnSaleTime"
                        type="datetime"
                        value-format="timestamp"
                        :placeholder="$t('pageKey300', '选择计划上架时间')"
                    />
                </el-form-item>
                <el-form-item
                    :label="$t('pageKey301', '计划下架时间')"
                    label-width="9em"
                >
                    <el-date-picker
                        v-model="formModel.planOffSaleTime"
                        type="datetime"
                        value-format="timestamp"
                        :placeholder="$t('pageKey302', '选择计划下架时间')"
                    />
                </el-form-item>
                <el-form-item
                    prop="isPresellGoods"
                    :label="$t('pageKey303', '是否预售商品')"
                    label-width="9em"
                    v-if="userData.shop && (userData.shop.goodsConfig & 2) != 0"
                >
                    <el-switch
                        v-model="formModel.isPresellGoods"
                    />
                </el-form-item>
                <el-form-item
                    key="beginPresellTime"
                    prop="beginPresellTime"
                    :label="$t('pageKey304', '预售开始时间')"
                    label-width="9em"
                    v-if="formModel.isPresellGoods"
                >
                    <el-date-picker
                        v-model="formModel.beginPresellTime"
                        type="datetime"
                        value-format="timestamp"
                        :placeholder="$t('pageKey305', '选择预售开始时间')"
                    />
                </el-form-item>
                <el-form-item
                    key="presellEndPeriod"
                    prop="presellEndPeriod"
                    :label="$t('pageKey306', '截单周期(天)')"
                    label-width="9em"
                    v-if="formModel.isPresellGoods"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        v-model.number="formModel.presellEndPeriod"
                    />
                    {{ $t('pageKey307', '(截单日期=预售开始时间+截单周期)') }}
                </el-form-item>
                <el-form-item
                    key="presellShipPeriod"
                    prop="presellShipPeriod"
                    :label="$t('pageKey308', '发货周期(天)')"
                    label-width="9em"
                    v-if="formModel.isPresellGoods"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        v-model.number="formModel.presellShipPeriod"
                    />
                    {{ $t('pageKey309', '(预计发货时间=预售开始时间+截单周期+发货周期)') }}
                </el-form-item>
                <!--<el-form-item
prop="baseSales"
label="基础销量"
>
<el-input-number
controls-position="right"
:min="0"
:precision="0"
v-model.number="formModel.baseSales"
/>
</el-form-item>-->
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
                :header="$t('pageKey310', '购买限制')"
                v-if="userData.appId !== 3"
            >
                <el-form-item
                    :label="$t('pageKey311', '订单最小购买数量')"
                >
                    <el-input
                        type="number"
                        :placeholder="$t('pageKey238', '请输入')"
                        v-model="formModel.minPerOrderQuantity"
                    />
                </el-form-item>
                <el-form-item
                    :label="$t('pageKey312', '订单最大购买数量')"
                >
                    <el-input
                        type="number"
                        :placeholder="$t('pageKey238', '请输入')"
                        v-model="formModel.maxPerOrderQuantity"
                    />
                </el-form-item>
                <el-form-item
                    :label="$t('pageKey313', '会员限购数量')"
                >
                    <el-input
                        type="number"
                        :placeholder="$t('pageKey238', '请输入')"
                        v-model="formModel.maxMbOrderQuantity"
                    />
                </el-form-item>
                <el-form-item
                    :label="$t('pageKey314', '会员等级限购')"
                    v-if="buyerLevelList.length !== 0"
                >
                    <el-checkbox-group v-model="buyerLevelLimit">
                        <el-checkbox
                            v-for="item in buyerLevelList"
                            :key="item.id"
                            :label="item.id"
                        >
                            {{ item.levelName }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <!--                <el-form-item
                            label="分销等级限购"
                            v-if="distributorLevelList.length !== 0"
                        >
                            <el-checkbox-group v-model="distributorLevelLimit">
                                <el-checkbox
                                    v-for="item in distributorLevelList"
                                    :key="item.id"
                                    :label="item.id"
                                >
                                    {{ item.levelName }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>-->
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
                :header="$t('pageKey315', '分销设置')"
                v-if="userData.shop && (((1 & userData.shop.distributionIncomeMode) !== 0)
                    || ((2 & userData.shop.distributionIncomeMode) !== 0)
                    || ((4 & userData.shop.distributionIncomeMode) !== 0)
                    || ((16 & userData.shop.distributionIncomeMode) !== 0)
                    || ((32 & userData.shop.distributionIncomeMode) !== 0))
                    && userData.appId !== 3"
            >
                <el-form-item
                    prop="shareIncomeRate"
                    :label="$t('pageKey316', '分销分享赚比例(%)')"
                    label-width="11em"
                    v-if="userData.shop && (1 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="formModel.shareIncomeRate"
                    />
                </el-form-item>
                <el-form-item
                    prop="topIncomeRate"
                    :label="$t('pageKey317', '分销顶级提成比例(%)')"
                    label-width="11em"
                    v-if="userData.shop && (2 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="formModel.topIncomeRate"
                    />
                </el-form-item>
                <el-form-item
                    prop="levelIncomeRate"
                    :label="$t('pageKey318', '分销层级提成比例')"
                    label-width="11em"
                    v-if="userData.shop && (4 & userData.shop.distributionIncomeMode) !== 0 || (8 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="levelIncomeRateList"
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="name"
                            :label="$t('pageKey319', '分销层级')"
                            width="120"
                        />
                        <el-table-column
                            prop="levelIncomeRate"
                            :label="$t('pageKey320', '默认比例(%)')"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    size="mini"
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    v-model.number="scope.row.levelIncomeRate"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-for="(item,index) in distributorLevelList"
                            :key="index"
                            :label="item.levelName + $t('pageKey321', '提成比例(%)')"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    size="mini"
                                    controls-position="right"
                                    :precision="2"
                                    v-model.number="scope.row.distributorLevelRateList[index].levelIncomeRate"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item
                    prop="liveShareIncomeRate"
                    :label="$t('pageKey322', '直播分享赚比例(%)')"
                    label-width="11em"
                    v-if="userData.shop && (16 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="formModel.liveShareIncomeRate"
                    />
                </el-form-item>
                <el-form-item
                    prop="anchorIncomeRate"
                    :label="$t('pageKey323', '主播提成比例(%)')"
                    label-width="11em"
                    v-if="userData.shop && (32 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="formModel.anchorIncomeRate"
                    />
                </el-form-item>
            </el-card>
        </el-form>

        <div class="ta-c pa-a">
            <el-button
                type="primary"
                @click="onSave"
            >
                {{ $t('pageKey73', '保存') }}
            </el-button>
            <el-button
                type="success"
                @click="onSave(true)"
            >
                {{ $t('pageKey324', '保存并上架') }}
            </el-button>
        </div>

        <!-- 选择规格 -->
        <el-dialog
            :title="$t('pageKey325', '选择规格')"
            center
            width="1200px"
            :visible.sync="selectSkuDialog.isVisible"
            @closed="onSelectSkuCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectSkuDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectSkuDialog.queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                >
                    <el-input
                        v-model="selectSkuDialog.queryFormModel.goodsName"
                        :placeholder="$t('pageKey205', '请输入商品名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品编号')"
                >
                    <el-input
                        v-model="selectSkuDialog.queryFormModel.goodsSn"
                        :placeholder="$t('pageKey203', '请输入商品编号')"
                    />
                </el-form-item>
                <el-form-item
                    prop="skuSn"
                    :label="$t('pageKey242', 'sku编码')"
                >
                    <el-input
                        v-model="selectSkuDialog.queryFormModel.skuSn"
                        :placeholder="$t('pageKey326', '请输入sku编码')"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopTagIdList"
                    :label="$t('pageKey327', '商品分组')"
                >
                    <RemoteSelect
                        multiple
                        remote="/sp/shopTag/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'tagName'}"
                        v-model="selectSkuDialog.queryFormModel.shopTagIdList"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectSkuDialogQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectSkuDialog"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectSkuDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="skuId"
                    ref="selectSkuDialogTable"
                    @selection-change="onSelectSkuDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="specImageUrl"
                        :label="$t('pageKey215', '商品图片')"
                        width="135"
                    >
                        <template slot-scope="scope">
                            <image-list :data="scope.row.specImageUrl"/>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsName"
                        :label="$t('pageKey204', '商品名称')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        :label="$t('pageKey202', '商品编号')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="skuSn"
                        :label="$t('pageKey242', 'sku编码')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="specDesc"
                        :label="$t('pageKey291', 'sku规格')"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectSkuDialog.pagination"
                        @input="onSelectSkuDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectSkuConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectSkuCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>

        <!--选择商品-->
        <el-dialog
            :title="$t('pageKey328', '选择商品')"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    :label="$t('pageKey204', '商品名称')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsName"
                        :placeholder="$t('pageKey205', '请输入商品名称')"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    :label="$t('pageKey202', '商品编号')"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsSn"
                        :placeholder="$t('pageKey203', '请输入商品编号')"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopTagIdList"
                    :label="$t('pageKey327', '商品分组')"
                >
                    <RemoteSelect
                        multiple
                        remote="/sp/shopTag/select"
                        :placeholder="$t('pageKey238', '请输入')"
                        :props="{id:'id',name:'tagName'}"
                        v-model="selectDialog.queryFormModel.shopTagIdList"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        {{ $t('pageKey9', '查询') }}
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        {{ $t('pageKey10', '重置') }}
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="goodsId"
                    ref="selectGoodsDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="mainImageUrl"
                        :label="$t('pageKey215', '商品图片')"
                        width="135"
                    >
                        <template slot-scope="scope">
                            <image-list :data="scope.row.mainImageUrl"/>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsName"
                        :label="$t('pageKey204', '商品名称')"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        :label="$t('pageKey202', '商品编号')"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    {{ $t('pageKey19', '确 定') }}
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    {{ $t('pageKey20', '取 消') }}
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import GoodsSpu from './_goodsSpu';
import GoodsSpec from './_goodsSpec';

export default {
    name: 'AddGoods',
    mixins: [pagesMixin],
    components: { GoodsSpu, GoodsSpec },
    data() {
        return {
            type: '',
            GoodsCatApi: this.$api.Gd.GoodsCat,
            formModel: {
                id: '',
                goodsType: '',
                goodsCatName: '',
                goodsName: '',
                goodsShortName: '',
                goodsSubtitle: '',
                goodsKeywords: '',
                goodsImage: [],
                goodsDetailImage: [],
                goodsVideo: [],
                shopGoodsCatIds: [], // 店铺类目
                shopStoreGoodsCatId: [],
                goodsCatId: '', // 商品后台类目
                supplierId: '',
                supplierName: '',
                freightTemplateId: '',
                premiumTemplateId: '',
                aftersaleTemplateId: '',
                gstRate: undefined,

                /* 价格库存 */
                goodsSn: '',
                goodsSpecList: [], // 商品规格
                skuSpecList: [],
                stock: '',
                costPrice: '',
                price: '',
                marketPrice: '',
                isShowMarketPrice: true,

                weight: '',
                netWeight: '',
                grossWeight: '',

                /* 购买限制 */
                minPerOrderQuantity: '',
                maxPerOrderQuantity: '',
                maxMbOrderQuantity: '',
                goodsPermission: '',

                planOnSaleTime: '',
                planOffSaleTime: '',

                isPresellGoods: false,
                beginPresellTime: '',
                presellEndPeriod: '',
                presellShipPeriod: '',

                goodsSendMode: '',

                goodsImageBaseUrl: 'http://imgp.zteup.com/upload/sys/goodsimage',

                isPrivateCustom: '',
                customFrontImage: [],
                customBackImage: [],
                customDefaultImage: [],
                customCombineConfig: '',

                baseSales: 0,

                shareIncomeRate: undefined,
                topIncomeRate: undefined,
                liveShareIncomeRate: undefined,
                anchorIncomeRate: undefined,

                storeInitGoodsCatId: [],
                shopStoreLevelId: [],
                goodsLabelId: [],

                isCombinationGoods: false,
                isCombinationSplit: true,

                goodsDescription: '',
                storeId: '',
                taxRate: '',
            },
            formRules: {
                goodsType: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                goodsSendMode: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                goodsCatName: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                goodsName: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                goodsImage: {
                    type: 'array',
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                /* goodsDetailImage: {
                    type: 'array',
                    required: true,
                    message: '不能为空',
                }, */
                goodsCatId: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                supplierId: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                freightTemplateId: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                /* 价格库存 */
                goodsSn: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                stock: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                costPrice: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                price: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                beginPresellTime: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                presellEndPeriod: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                presellShipPeriod: {
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                customFrontImage: {
                    type: 'array',
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                customBackImage: {
                    type: 'array',
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
                customDefaultImage: {
                    type: 'array',
                    required: true,
                    message: this.$t('pageKey261', '不能为空'),
                },
            },
            // 分销层级设置
            levelIncomeRateList: [],
            buyerLevelList: [],
            distributorLevelList: [],
            buyerLevelLimit: [],
            distributorLevelLimit: [],
            goodsSendModeList: '',
            allGoodsSendModeList: [1, 2, 3, 4, 5, 6],
            // 商品表格数据
            combinationGoodsList: [],
            // 商品弹窗
            selectSkuDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsName: '', // 商品名称
                    goodsSn: '', // 商品编号
                    skuSn: '',
                    shopTagIdList: [],
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 已选择数据
                currentTableSelect: [],
            },
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsName: '', // 商品名称
                    goodsSn: '', // 商品编号
                    shopTagIdList: [],
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
        };
    },
    computed: {
        goodsId() {
            return this.$route.params.goodsId;
        },
        hasGoodsSpecList() {
            return this.formModel.goodsSpecList && this.formModel.goodsSpecList.length;
        },
        excludeGoodsSendModeList() {
            const list = [];
            const list1 = this.goodsSendModeList.split(',');
            this.allGoodsSendModeList.forEach(item => {
                if (list1.indexOf(`${item}`) === -1) {
                    list.push(item);
                }
            });
            return list;
        },
    },
    methods: {
        recoverFormModel(data) {
            Object.keys(this.formModel).forEach(key => {
                // if (key === 'goodsSpecList') return;
                // if (key === 'goodsImage') return;
                // if (key === 'goodsDetailImage') return;
                // if (key === 'skuSpecList') return;
                const val = data[key];
                this.formModel[key] = val === undefined || val === null ? this.formModel[key] : val;
            });
        },
        getSaveData() {
            const data = {
                ...this.formModel,
            };

            // 店铺类目
            data.shopGoodsCatIds = data.shopGoodsCatIds
                .reduce((prev, curr) => {
                    prev.push(curr);
                    return prev;
                }, [])
                .join(',');
            data.goodsImage = data.goodsImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
            data.goodsDetailImage = data.goodsDetailImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
            data.goodsVideo = data.goodsVideo
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');

            data.goodsSpecList = data.goodsSpecList.map(spec => {
                const _spec = {
                    pid: spec.pid,
                    pn: spec.pn,
                    v: spec.v || [],
                };
                _spec.v = _spec.v.map(sku => {
                    const vimgArr = sku.vimgModel || [];
                    const vimgModel = vimgArr[0] || {};
                    return {
                        vid: sku.vid,
                        vn: sku.vn,
                        vimg: vimgModel.name || '',
                    };
                });
                return _spec;
            });

            this.levelIncomeRateList.forEach(item => {
                const distributorLevelRateList = [];
                item.distributorLevelRateList.forEach(item1 => {
                    distributorLevelRateList.push({ id: item1.id, levelIncomeRate: item1.levelIncomeRate });
                });
                item.distributorLevelRateList = distributorLevelRateList;
            });
            data.levelIncomeRate = JSON.stringify(this.levelIncomeRateList);
            // data.skuSpecList;
            const goodsPermissionList = [];
            this.buyerLevelLimit.forEach(item => {
                goodsPermissionList.push(`buyerLevel-${item}`);
            });
            this.distributorLevelLimit.forEach(item => {
                goodsPermissionList.push(`distributorLevel-${item}`);
            });
            if (goodsPermissionList.length === 0) {
                data.goodsPermission = '';
            } else {
                data.goodsPermission = goodsPermissionList.join(',');
            }

            data.customFrontImage = (data.customFrontImage || [])
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
            data.customBackImage = (data.customBackImage || [])
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
            data.customDefaultImage = (data.customDefaultImage || [])
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
            if (data.storeInitGoodsCatId) {
                data.storeInitGoodsCatIds = data.storeInitGoodsCatId.join(',');
            }
            if (data.shopStoreLevelId) {
                data.shopStoreLevelIds = data.shopStoreLevelId.join(',');
            }
            if (data.goodsLabelId) {
                data.goodsLabelIds = data.goodsLabelId.join(',');
            }
            if (data.shopStoreGoodsCatId) {
                data.shopStoreGoodsCatIds = data.shopStoreGoodsCatId.join(',');
            }
            data.combinationGoodsList = JSON.stringify(this.combinationGoodsList);

            return data;
        },
        onSave(publish) {
            const { form } = this.$refs;
            const validateList = [form];
            if (this.$refs.GoodsSpu) validateList.push(this.$refs.GoodsSpu);
            if (this.$refs.GoodsSpec && this.$refs.GoodsSpec.form) validateList.push(this.$refs.GoodsSpec.form);
            this.$utils
                .validateForm(validateList, true)
                .then((/* valid */) => {
                    const data = this.getSaveData();
                    let fn = 'save';
                    if (publish === true) {
                        fn = 'saveAndPublish';
                    }
                    this.$api.Gd.Goods[fn](data).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.$router.back();
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        initLevelIncomeRateList() {
            if (this.userData.appId !== 1) {
                const newlevelIncomeRateList = [];
                for (let num = 1; num <= this.userData.shop.distributionLevel; num += 1) {
                    let isExit = false;
                    this.levelIncomeRateList.forEach(item => {
                        if (item.id === num) {
                            isExit = true;
                            newlevelIncomeRateList.push(item);
                        }
                    });
                    if (!isExit) {
                        const levelIncomeRate = {};
                        levelIncomeRate.id = num;
                        levelIncomeRate.name = `${num} ${this.$t('pageKey329', '级分销商')}`;
                        newlevelIncomeRateList.push(levelIncomeRate);
                    }
                }
                newlevelIncomeRateList.sort((a, b) => a.id - b.id);
                newlevelIncomeRateList.forEach(item => {
                    if (!item.distributorLevelRateList) item.distributorLevelRateList = [];
                    const distributorLevelRateList = [];
                    this.distributorLevelList.forEach(item1 => {
                        let isExit = false;
                        item.distributorLevelRateList.forEach(item2 => {
                            if (item1.id === item2.id) {
                                isExit = true;
                                distributorLevelRateList.push(item2);
                            }
                        });
                        if (!isExit) {
                            distributorLevelRateList.push({ id: item1.id, levelIncomeRate: undefined });
                        }
                    });
                    item.distributorLevelRateList = distributorLevelRateList;
                });
                this.levelIncomeRateList = [...newlevelIncomeRateList];
            }
        },
        initBuyerLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                this.buyerLevelList = res;
            });
        },
        async initDistributorLevelList() {
            await this.$api.Mb.DistributorLevel.select().then(json => {
                const res = json.data.data;
                this.distributorLevelList = res;
            });
        },
        changeSupplier() {
            this.$api.Gd.Supplier.getDetail({ id: this.formModel.supplierId }).then(json => {
                if (json.data.data.goodsSendMode) {
                    this.formModel.goodsSendMode = json.data.data.goodsSendMode;
                }
            });
        },
        changeIsCombinationGoods(val) {
            if (val) {
                // 开启组合产品 清空规格数据
                this.formModel.goodsSpecList = [];
                this.formModel.skuSpecList = [];
            }
        },
        // 选择规格
        onAddSelectSku() {
            this.selectSkuDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectSkuDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectSkuDialogTable) {
                    this.$refs.selectSkuDialogTable.clearSelection();
                }
                this.combinationGoodsList.forEach(item => {
                    if (item.type === 2) {
                        this.$refs.selectSkuDialogTable.toggleRowSelection(item, true);
                    }
                });
            });
        },
        onSelectSkuDialogQuery(pagination) {
            return this.$api.Gd.Goods.findSkuMapList({
                ...this.selectSkuDialog.queryFormModel,
                ...pagination,
                goodsSendModeList: this.formModel.goodsSendMode,
                supplierId: this.formModel.supplierId,
                isCombinationGoods: false,
                isMultipleGoods: 0,
            }).then(json => {
                const res = json.data;
                this.selectSkuDialog.tableData = res.data;
                this.selectSkuDialog.pagination = res.pagination;
            });
        },
        onSelectSkuDialogSelectionChange(val) {
            this.selectSkuDialog.currentTableSelect = val;
        },
        onResetSelectSkuDialog() {
            this.$refs.selectSkuDialogQueryForm.resetFields();
        },
        onSelectSkuConfirm() {
            // 插入新数据
            const list = [];
            // 插入旧数据
            this.combinationGoodsList.forEach(oldItem => {
                if (oldItem.type === 1) {
                    list.push({ ...oldItem });
                } else if (oldItem.type === 2) {
                    // 判断是否已选择
                    this.selectSkuDialog.currentTableSelect.forEach(item => {
                        if (item.skuId === oldItem.skuId) {
                            list.push({ ...oldItem });
                        }
                    });
                }
            });

            this.selectSkuDialog.currentTableSelect.forEach(item => {
                let isHave = false;
                this.combinationGoodsList.forEach(oldItem => {
                    if (oldItem.type === 2 && item.skuId === oldItem.skuId) {
                        isHave = true;
                    }
                });
                if (!isHave) {
                    // 添加新数据
                    item.id = null;
                    list.push({ ...item, combinationQuantity: 1, combinationPrice: item.price, type: 2 });
                }
            });
            this.combinationGoodsList = [...list];
            this.changeGoodsPrice();
            this.selectSkuDialog.isVisible = false;
        },
        onSelectSkuCancel() {
            this.selectSkuDialog.isVisible = false;
        },
        // 选择商品
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onAddSelectGoods() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectGoodsDialogTable) {
                    this.$refs.selectGoodsDialogTable.clearSelection();
                }
                this.combinationGoodsList.forEach(item => {
                    if (!item.skuId) {
                        this.$refs.selectGoodsDialogTable.toggleRowSelection(item, true);
                    }
                });
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Gd.Goods.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
                goodsSendModeList: this.formModel.goodsSendMode,
                supplierId: this.formModel.supplierId,
                isCombinationGoods: false,
                isMultipleGoods: 1,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            // 插入新数据
            const list = [];
            this.combinationGoodsList.forEach(oldItem => {
                if (oldItem.type === 2) {
                    list.push({ ...oldItem });
                } else if (oldItem.type === 1) {
                    // 判断是否已选择
                    this.selectSkuDialog.currentTableSelect.forEach(item => {
                        if (item.goodsId === oldItem.goodsId) {
                            list.push({ ...oldItem });
                        }
                    });
                }
            });
            this.selectDialog.currentTableSelect.forEach(item => {
                let isHave = false;
                this.combinationGoodsList.forEach(oldItem => {
                    if (oldItem.type === 1 && item.goodsId === oldItem.goodsId) {
                        isHave = true;
                    }
                });
                if (!isHave) {
                    // 添加新数据
                    item.id = null;
                    if (item.price.concat('-')) [item.price] = item.price.split('-');
                    item.skuList.forEach(item1 => {
                        item1.addPrice = item1.price - item.price;
                    });
                    list.push({ ...item, combinationQuantity: 1, combinationPrice: item.price, type: 1 });
                }
            });
            this.combinationGoodsList = [...list];
            this.changeGoodsPrice();
            this.selectDialog.isVisible = false;
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelectItem(list, row) {
            list.splice(list.indexOf(row), 1);
            this.changeGoodsPrice();
        },
        changeGoodsPrice() {
            let amount = 0;
            this.combinationGoodsList.forEach(item => {
                amount += item.combinationPrice * item.combinationQuantity;
            });
            this.formModel.price = amount;
        },
        changeGoodsSendMode() {
            this.combinationGoodsList = [];
        },
    },
    async created() {
        if (this.$route.name === 'cgd-goods-addCustomGoods') {
            // 定制商品
            this.formModel.isPrivateCustom = 1;
            this.type = 2;
        } else {
            this.formModel.isPrivateCustom = 0;
            this.type = 1;
            if (this.$route.name === 'gd-goods-addGoods') {
                this.goodsSendModeList = '1,5';
                this.formModel.goodsSendMode = 1;
            } else if (this.$route.name === 'gd-goods-addBondedGoods') {
                this.goodsSendModeList = '4';
                this.formModel.goodsSendMode = 4;
            } else if (this.$route.name === 'gd-goods-addCommunityGoods') {
                this.goodsSendModeList = '6';
                this.formModel.goodsSendMode = 6;
            } else {
                this.goodsSendModeList = '2';
                this.formModel.goodsSendMode = 2;
            }
        }
        try {
            this.initBuyerLevelList();
            await this.initDistributorLevelList();
            if (this.goodsId) {
                this.$api.Gd.Goods.getGoodsDetailVo({ id: this.goodsId }).then(json => {
                    const res = json.data;
                    const { data } = res;
                    data.goodsImageBaseUrl = data.goodsImageBaseUrl.replace(/\/$/, '');

                    data.goodsImage = data.goodsImage
                        ? data.goodsImage
                              .split(',')
                              .map(item => ({ name: item, address: `${data.goodsImageBaseUrl}/${item}` }))
                        : [];
                    if (!data.goodsDetailImage) {
                        data.goodsDetailImage = [];
                    } else {
                        data.goodsDetailImage = data.goodsDetailImage
                            ? data.goodsDetailImage
                                  .split(',')
                                  .map(item => ({ name: item, address: `${data.goodsImageBaseUrl}/${item}` }))
                            : [];
                    }
                    if (!data.goodsVideo) {
                        data.goodsVideo = [];
                    } else {
                        data.goodsVideo = data.goodsVideo
                            .split(',')
                            .map(item => ({ name: item, address: `${data.goodsImageBaseUrl}/${item}` }));
                    }
                    if (data.customFrontImage) {
                        data.customFrontImage = data.customFrontImage
                            .split(',')
                            .map(item => ({ name: item, address: `${data.goodsImageBaseUrl}/${item}` }));
                    }
                    if (data.customBackImage) {
                        data.customBackImage = data.customBackImage
                            .split(',')
                            .map(item => ({ name: item, address: `${data.goodsImageBaseUrl}/${item}` }));
                    }
                    if (data.customDefaultImage) {
                        data.customDefaultImage = data.customDefaultImage
                            .split(',')
                            .map(item => ({ name: item, address: `${data.goodsImageBaseUrl}/${item}` }));
                    }
                    // 店铺类目
                    data.shopGoodsCatIds = data.shopGoodsCatIds
                        ? data.shopGoodsCatIds.split(',').map(item => +item)
                        : [];
                    data.storeInitGoodsCatId = data.storeInitGoodsCatIds
                        ? data.storeInitGoodsCatIds.split(',').map(item => +item)
                        : [];
                    data.shopStoreLevelId = data.shopStoreLevelIds
                        ? data.shopStoreLevelIds.split(',').map(item => +item)
                        : [];
                    data.goodsLabelId = data.goodsLabelIds ? data.goodsLabelIds.split(',').map(item => +item) : [];
                    data.shopStoreGoodsCatId = data.shopStoreGoodsCatIds
                        ? data.shopStoreGoodsCatIds.split(',').map(item => +item)
                        : [];
                    // 商品规格
                    const arr = [];
                    // eslint-disable-next-line
                    for (let i = 0, item; (item = data.goodsSpecList[i++]); ) {
                        // eslint-disable-next-line
                        for (let n = 0, item2; (item2 = item.v[n++]); ) {
                            item2.spec = `${item.pid}:${item2.vid}`;
                            item2.desc = `${item.pn}:${item2.vn}`;
                        }
                        arr.push(item);
                    }
                    data.goodsSpecList = arr;
                    this.recoverFormModel(data);

                    this.levelIncomeRateList = JSON.parse(data.levelIncomeRate) || [];
                    this.initLevelIncomeRateList();

                    if (data.goodsPermission) {
                        data.goodsPermission.split(',').forEach(item => {
                            const limitItem = item.split('-');
                            if (limitItem[0] === 'buyerLevel') {
                                this.buyerLevelLimit.push(+limitItem[1]);
                            } else if (limitItem[0] === 'distributorLevel') {
                                this.distributorLevelLimit.push(+limitItem[1]);
                            }
                        });
                    }

                    this.combinationGoodsList = JSON.parse(data.combinationGoodsList) || [];
                });
            } else {
                this.initLevelIncomeRateList();
            }
        } catch (e) {
            console.error(e);
        }
    },
};
</script>

<style lang="scss">
</style>
